<template>
  <transition
    enter-active-class="animated fadeIn fastest"
    leave-active-class="animated fadeOut fastest"
  >
    <v-snackbar
      v-if="message && message.length"
      :id="snackerId"
      class="fs-unmask"
      :value="message"
      :timeout="timeout"
      v-bind="$attrs"
      left
    >
      <div class="container-snackbar">
        <div class="container-icon-progress">
          <v-progress-circular
            v-if="loading"
            size="22"
            indeterminate
            width="3"
          />
          <v-icon
            v-if="variant"
            :class="iconColorClass"
            small
          >
            {{ iconName }}
          </v-icon>
        </div>
        <div class="container-content">
          <p class="mb-0">
            {{ message }}
          </p>
          <p
            v-if="linkText && (callback || linkUrl)"
            class="mt-1 mb-0"
          >
            <a
              v-if="callback"
              class="snack-link"
              @click="callback()"
            >{{ linkText }}</a>
            <a
              v-if="linkUrl"
              class="snack-link"
              :href="linkUrl"
              target="_blank"
            >{{ linkText }}</a>
          </p>
        </div>
        <div
          class="container-dismiss-button"
        >
          <v-btn
            icon
            small
            @click="close"
          >
            <v-icon>
              {{ closeIcon }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-snackbar>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  SNACK_GET_MESSAGE,
  SNACK_GET_LOADING,
  SNACK_GET_VARIANT,
  SNACK_GET_TIMEOUT,
  SNACK_GET_LINK_TEXT,
  SNACK_GET_LINK_URL,
  SNACK_GET_CALLBACK
} from '@/store/get-types'
import { SNACK_SET_MESSAGE } from '@/store/mutation-types'
import {
  SNACKER_ID,
  SUCCESS,
  WARNING,
  ERROR,
  SUCCESS_ICON,
  ERROR_ICON,
  WARNING_ICON,
  CLOSE_ICON,
  DEFAULT_TIMEOUT
} from '@/components/shared/alert/snack-constants'

export default {
  name: 'Snacker',
  data () {
    return {
      interval: null,
      closeIcon: CLOSE_ICON,
      snackerId: SNACKER_ID
    }
  },
  computed: {
    ...mapGetters({
      message: SNACK_GET_MESSAGE,
      loading: SNACK_GET_LOADING,
      timeout: SNACK_GET_TIMEOUT || DEFAULT_TIMEOUT,
      variant: SNACK_GET_VARIANT,
      linkUrl: SNACK_GET_LINK_URL,
      linkText: SNACK_GET_LINK_TEXT,
      callback: SNACK_GET_CALLBACK
    }),
    iconName () {
      switch (this.variant) {
        case ERROR:
          return ERROR_ICON
        case WARNING:
          return WARNING_ICON
        case SUCCESS:
          return SUCCESS_ICON
      }
      return ''
    },
    iconColorClass () {
      switch (this.variant) {
        case ERROR:
          return 'icon-red'
        case WARNING:
          return 'icon-yellow'
        case SUCCESS:
          return 'icon-green'
      }
      return ''
    }
  },
  watch: {
    message (newMessage) {
      if (newMessage && this.timeout > 0) {
        if (this.interval) {
          clearTimeout(this.interval)
          this.interval = null
        }

        this.interval = setTimeout(() => {
          this.close()
        }, this.timeout)
      }
    }
  },
  methods: {
    close () {
      if (this.interval) {
        clearTimeout(this.interval)
        this.interval = null
      }
      this.$store.commit(SNACK_SET_MESSAGE, '')
    }
  }
}
</script>

<style lang="scss" scoped>
#snackbar-parent-container {
  margin-inline-start: 0.5rem;
}
#snackbar-parent-container ::v-deep .v-snack__wrapper {
  width: initial;
  background-color: var(--t-color-navigation-surface-inverted);
}
p {
  display: block;
  font-weight: var(--t-font-weight-6);
  color: var(--t-color-text-inverted);
  margin: 0;
}
#snackbar-parent-container ::v-deep .v-snack__content {
  display: initial;
}
.container-snackbar {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
.container-icon-progress {
  display: flex;
  flex-shrink: 0;
}
.container-content {
  flex: 1;
  margin-block-start: .125rem;
}
.container-secondary-actions {
  display: flex;
  margin-inline-start: -3rem;
}
.v-icon {
  color: var(--t-color-text-inverted);
  border-radius: var(--t-radius-round);
  padding: .25rem;
}
.icon-green {
  background-color: var(--t-color-status-success);
}
.icon-red {
  background-color: var(--t-color-status-danger);
}
.icon-yellow {
  background-color: var(--t-color-status-warning);
}
.container-dismiss-button {
  margin-block-start: -.125rem;
}
.v-snack {
  width: 300px !important;
  max-width: 500px !important;
  z-index: 11000 !important;
}
#app a.snack-link {
  color: var(--t-color-text-link-inverted) !important;
  text-decoration: underline;
}
</style>
