import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'
import * as defaultTypes from '../../components/shared/constants/localstorage.constants'
import { HttpClient, httpError } from '../../components/shared/security/http-client'
import AuthenticationProvider from '../../components/shared/security/authentication-provider'
import {
  RG_ACH_DRAW
} from '@/components/shared/split/split-constants'
import router from '@/router'
import {
  ORDER_CHECKOUT_ROUTE
} from '@/router/route-name-constants'
import {
  hasAnyAuthority
} from '@/components/shared/security/authorized-plugin'
import {
  FUNDING_MANAGE,
  VIEW_BALANCE_ACCESS
} from '@/components/shared/constants/authority.constants'
import { CUSTOMERS_SIZE } from '@/components/shared/constants'

const accountIsOwnedByCurrentPlatform = (account, platformClientId) => {
  return account && account.platformClientId === platformClientId
}

const state = {
  selectedCustomerAccount: null,
  onlyShowUsdAccounts: false,
  customerAccounts: [],
  accountsByGroups: []
}

const getters = {
  [getTypes.GET_SELECTED_CUSTOMER_ACCOUNT]: state => {
    if (state.selectedCustomerAccount) {
      return state.selectedCustomerAccount
    } else {
      if (AuthenticationProvider && AuthenticationProvider.isEmulationSession()) {
        // We do _NOT_ want to get the account from local storage for an emulation session
        return null
      } else {
        return JSON.parse(localStorage.getItem(defaultTypes.SELECTED_CUSTOMER_ACCOUNT))
      }
    }
  },
  [getTypes.GET_ONLY_SHOW_USD_ACCOUNTS]: (state, getters) => {
    // Only show USD accounts is being used in conjunction with the ACH split,
    // doing a split check gives us extra protection and safety.
    const achSplitIsEnabled = getters[getTypes.GET_TREATMENT_ENABLED](RG_ACH_DRAW)
    if (achSplitIsEnabled) {
      return state.onlyShowUsdAccounts
    } else {
      // We should not risk showing only USD accounts accidentally to
      // non ACH users.
      return false
    }
  },
  [getTypes.GET_CUSTOMER_ACCOUNTS]: (state) => {
    return state.customerAccounts
  },
  [getTypes.GET_ACCOUNTS_BY_GROUPS]: (state) => {
    return state.accountsByGroups
  }
}

const mutations = {
  [mutationTypes.SET_SELECTED_CUSTOMER_ACCOUNT] (state, customerAccount) {
    state.selectedCustomerAccount = customerAccount
    localStorage.setItem(defaultTypes.SELECTED_CUSTOMER_ACCOUNT, JSON.stringify(customerAccount))
  },
  [mutationTypes.SET_ONLY_SHOW_USD_ACCOUNTS] (state, onlyShowUsdAccounts) {
    state.onlyShowUsdAccounts = onlyShowUsdAccounts
  },
  [mutationTypes.SET_ACCOUNTS_FOR_SELECTED_CUSTOMER] (state, customerAccountsResponse) {
    state.customerAccounts = customerAccountsResponse.data
      .filter((customer) => customer.accountName)
      .sort((a, b) => {
        // If an account is selected, it should be sorted to the top
        if (a?.accountID === state.selectedCustomerAccount?.accountID) return -1
        if (b?.accountID === state.selectedCustomerAccount?.accountID) return 1

        // Otherwise, sort accounts alphabetically
        return a.accountName.localeCompare(b.accountName)
      })
  },
  [mutationTypes.SET_ACCOUNTS_BY_GROUP] (state, accountGroups) {
    state.accountsByGroups = accountGroups
  }
}

const actions = {
  [actionTypes.CLEAR_SELECTED_CUSTOMER_ACCOUNT] ({ commit }) {
    // explicitly set the value to null, since JSON.parse does _not_ work with undefined (as it's not a valid JSON token)
    commit(mutationTypes.SET_SELECTED_CUSTOMER_ACCOUNT, null)
  },
  async [actionTypes.GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC] ({ commit, getters, dispatch }, forceReload) {
    const existingSelectedCustomerAccount = getters[getTypes.GET_SELECTED_CUSTOMER_ACCOUNT]
    const { platformClientId } = getters[getTypes.GET_PLATFORM]
    if (!forceReload && accountIsOwnedByCurrentPlatform(existingSelectedCustomerAccount, platformClientId)) {
      if (existingSelectedCustomerAccount.active) {
        return existingSelectedCustomerAccount
      }
    }
    try {
      const customerAccountsResponse = await HttpClient.get('api/customers', { params: { size: CUSTOMERS_SIZE } })
      if (customerAccountsResponse && customerAccountsResponse.data && Array.isArray(customerAccountsResponse.data) && customerAccountsResponse.data[0]) {
        const firstActiveAccount = customerAccountsResponse.data.find((account) => account.active && account.accountName)
        const existingSelectedAccountFromResponse = existingSelectedCustomerAccount
          ? customerAccountsResponse.data.find((account) => account.customerID === existingSelectedCustomerAccount.customerID &&
            account.accountID === existingSelectedCustomerAccount.accountID &&
            account.active
          )
          : null

        if (existingSelectedAccountFromResponse) {
          commit(mutationTypes.SET_SELECTED_CUSTOMER_ACCOUNT, existingSelectedAccountFromResponse)
          commit(mutationTypes.SET_ACCOUNTS_FOR_SELECTED_CUSTOMER, customerAccountsResponse)
          return existingSelectedAccountFromResponse
        } else if (firstActiveAccount) {
          commit(mutationTypes.SET_SELECTED_CUSTOMER_ACCOUNT, firstActiveAccount)
          commit(mutationTypes.SET_ACCOUNTS_FOR_SELECTED_CUSTOMER, customerAccountsResponse)
          return firstActiveAccount
        } else {
          dispatch(actionTypes.CLEAR_SELECTED_CUSTOMER_ACCOUNT)
        }
      } else {
        dispatch(actionTypes.CLEAR_SELECTED_CUSTOMER_ACCOUNT)
      }
    } catch (error) {
      console.error(httpError(error, 'Could not get the first selected account'))
    }
  },
  async [actionTypes.FETCH_AND_SET_THE_SELECTED_ACCOUNT] ({ commit }, { customerIdentifier, accountIdentifier }) {
    try {
      const customerAccountResponse = await HttpClient.get(
        `api/customers/${customerIdentifier}/account/${accountIdentifier}`
      )
      if (customerAccountResponse && customerAccountResponse.data) {
        commit(mutationTypes.SET_SELECTED_CUSTOMER_ACCOUNT, customerAccountResponse.data)
      }
    } catch (error) {
      console.error(httpError(error, 'Could not fetch and set the current selected account'))
    }
  },
  async [actionTypes.UPDATE_THE_SELECTED_ACCOUNT] ({ commit, getters }) {
    const existingSelectedCustomerAccount = getters[getTypes.GET_SELECTED_CUSTOMER_ACCOUNT]
    const { platformClientId } = getters[getTypes.GET_PLATFORM]
    if (accountIsOwnedByCurrentPlatform(existingSelectedCustomerAccount, platformClientId)) {
      try {
        const customerAccountResponse = await HttpClient.get(
          `api/customers/${existingSelectedCustomerAccount.customerIdentifier}/account/${existingSelectedCustomerAccount.accountIdentifier}`
        )
        if (customerAccountResponse && customerAccountResponse.data) {
          commit(mutationTypes.SET_SELECTED_CUSTOMER_ACCOUNT, customerAccountResponse.data)
        }
      } catch (error) {
        console.error(httpError(error, 'Could not update the current selected account'))
      }
    }
  },
  async [actionTypes.UPDATE_TOKEN_FOR_CUSTOMER_ACCOUNT] ({ commit, getters, dispatch }, customerAccountToSet) {
    try {
      await AuthenticationProvider.updateCustomerAndAccountForToken(customerAccountToSet.customerID, customerAccountToSet.accountID)
      dispatch(actionTypes.SET_SELECTED_CUSTOMER_ACCOUNT, customerAccountToSet)

      if (router.currentRoute.name === ORDER_CHECKOUT_ROUTE &&
        !hasAnyAuthority([VIEW_BALANCE_ACCESS, FUNDING_MANAGE])) {
        // This forces the UI to call the backend to update hasInsufficientFunds the user switches
        // accounts on the checkout page. Without this hasInsufficientFunds will become stale.
        dispatch(actionTypes.NAMESPACED_UPDATE_ORDER_TOTAL_SUMMARY, null, { root: true })
      }
    } catch (error) {
      console.error(httpError(error, 'Could not create token for the current selected customer/account'))
      throw error
    }
  },
  [actionTypes.SET_SELECTED_CUSTOMER_ACCOUNT] ({ commit }, customerAccountToSet) {
    commit(mutationTypes.SET_SELECTED_CUSTOMER_ACCOUNT, customerAccountToSet)
  },
  [actionTypes.SET_ONLY_SHOW_USD_ACCOUNTS] ({ commit }, onlyShowUsdAccountsToSet) {
    commit(mutationTypes.SET_ONLY_SHOW_USD_ACCOUNTS, onlyShowUsdAccountsToSet)
  },
  async [actionTypes.SET_CUSTOMER_ACCOUNTS] ({ commit }) {
    try {
      const customerAccountsResponse = await HttpClient.get('api/customers', { params: { size: CUSTOMERS_SIZE } })
      if (customerAccountsResponse && customerAccountsResponse.data && Array.isArray(customerAccountsResponse.data) && customerAccountsResponse.data[0]) {
        commit(mutationTypes.SET_ACCOUNTS_FOR_SELECTED_CUSTOMER, customerAccountsResponse)
      }
    } catch (error) {
      console.error(httpError(error, 'Could not get the customer accounts'))
    }
  },
  async [actionTypes.FETCH_ACCOUNTS_BY_GROUPS] ({ commit }, fetchRequest) {
    let morePages = true
    let request
    if (fetchRequest) {
      request = fetchRequest
    } else {
      request = {
        params: {
          sort: 'displayName,asc',
          customerOnly: true,
          size: 50,
          page: 0
        }
      }
    }
    let accountGroups = []

    while (morePages) {
      try {
        const { data } = await HttpClient.get('/api/customers', request)
        if (!data.content || !data.content.length) {
          morePages = false
        }
        accountGroups = accountGroups.concat(data.content)
        request.params.page++
      } catch (error) {
        console.error(httpError(error, 'Could not get the customer groups'))
      }
    }
    commit(mutationTypes.SET_ACCOUNTS_BY_GROUP, accountGroups)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
