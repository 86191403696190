<template>
  <div>
    <div v-if="hasPaymentSources && selectedMethod">
      <div
        v-if="onlyOnePaymentSource"
        class="mb-4"
      >
        <funding-method-option
          :funding-method="paymentSources[0]"
          @show-dialog="showDialog"
        />
      </div>
      <div v-else-if="lessThanSixPaymentSources">
        <funding-method-radio-group
          :selected-method="selectedMethod"
          :funding-methods="paymentSources"
          @selected-method="selectMethod"
          @show-dialog="showDialog"
        />
      </div>
      <div v-else>
        <funding-method-dropdown
          :selected-method="selectedMethod"
          :funding-methods="paymentSources"
          @selected-method="selectMethod"
        />
      </div>
    </div>
    <div
      v-else
      class="mb-5"
    >
      <span data-testid="no-payment-options">
        {{ $t(`${i18nPrefix}.noPaymentMethods`) }}
      </span>
      <br>
      <span
        v-if="canAccessMultipleAccounts"
        data-testid="add-payment-multiple-accounts"
      >
        {{ $t(`${i18nPrefix}.addPaymentMultipleAccounts`) }}
      </span>
      <span
        v-else
        data-testid="add-payment-single-account"
      >
        {{ $t(`${i18nPrefix}.addPaymentSingleAccount`) }}
      </span>
    </div>
    <ach-pending-dialog
      :show-pending-dialog="showPendingDialog"
      :currency-code="currencyCode"
      :total-pending="totalPending"
      :total-balance="totalBalance"
      :available-balance="availableBalance"
      @hide-dialog="hideDialog"
    />
  </div>
</template>

<script>
import FundingMethodDropdown from '@/components/shared/lists/FundingMethodDropdown'
import FundingMethodOption from '@/components/shared/lists/FundingMethodOption'
import FundingMethodRadioGroup from '@/components/shared/lists/FundingMethodRadioGroup'
import AchPendingDialog from './AchPendingDialog.vue'
import { LAST_USED_PAYMENT_SOURCE } from '@/components/shared/constants/localstorage.constants'
import { PaymentSourceType } from '@/components/platform/funding/FundingConstants'
import {
  QUERY_COUNT, QUERY_DIRECTION,
  QUERY_PREDICATE,
  USD_CURRENCY_CODE
} from '@/components/platform/funding/ach/constants'
import { statusPending } from '@/components/platform/funding/history/__constance__/FundingHistoryConstance'
import { FUNDING_ACCESS } from '@/components/shared/constants/authority.constants'

export default {
  name: 'SelectPaymentMethod',

  components: {
    FundingMethodDropdown,
    FundingMethodOption,
    FundingMethodRadioGroup,
    AchPendingDialog
  },

  provide () {
    return {
      availableMessage: this.availableMessage
    }
  },

  props: {
    paymentSources: {
      type: Array,
      default: () => []
    },
    canAccessMultipleAccounts: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      i18nPrefix: 'portalfrontendApp.placeOrder.paymentMethod.selectPaymentMethod',
      selectedMethod: null,
      showPendingDialog: false,
      currencyCode: USD_CURRENCY_CODE,
      totalPending: '',
      availableBalance: '',
      totalBalance: '',
      availableMessage: {
        show: false
      }
    }
  },

  computed: {
    hasPaymentSources () {
      return this.paymentSources.length > 0
    },
    lessThanSixPaymentSources () {
      return this.paymentSources.length < 6
    },
    onlyOnePaymentSource () {
      return this.paymentSources.length === 1
    }
  },

  watch: {
    paymentSources () {
      if (!this.selectedMethod) {
        this.getPendingFunds()
        this.selectDefaultMethod()
      }
    }
  },

  mounted () {
    this.getPendingFunds()
    this.selectDefaultMethod()
  },

  methods: {
    selectDefaultMethod () {
      // no-op if there are no payment sources
      if (!this.hasPaymentSources) return

      const lastPaymentSource = localStorage.getItem(LAST_USED_PAYMENT_SOURCE)
      if (lastPaymentSource) {
        const lastMethod = this.paymentSources.find(method => method.paymentSourceIdentifier === lastPaymentSource)
        if (lastMethod) {
          this.selectMethod(lastMethod)
        }
      }
      if (!this.selectedMethod) {
        const method = this.paymentSources.find(m => m.default) ?? this.paymentSources[0]
        this.selectMethod(method)
      }
    },
    selectMethod (method) {
      this.selectedMethod = method
      this.$emit('payment-source-changed', method)
      if (method.paymentSourceIdentifier) {
        localStorage.setItem(LAST_USED_PAYMENT_SOURCE, method.paymentSourceIdentifier)
      }
    },
    hideDialog () {
      this.showPendingDialog = false
    },
    showDialog () {
      this.showPendingDialog = true
    },
    async getPendingFunds () {
      if (!this.hasPaymentSources || !this.$hasAuthority(FUNDING_ACCESS)) return

      const params = {
        path: this.$route.path,
        query: {
          page: 0,
          pageSize: QUERY_COUNT,
          predicate: QUERY_PREDICATE,
          sortDirection: QUERY_DIRECTION
        }
      }
      let totalPending = 0
      let availableBalance = 0

      try {
        const resp = await this.$http.get('api/funding', { params })
        if (resp?.data) {
          resp.data.forEach((item) => {
            if (item.status === statusPending) {
              totalPending += Number(item.amount)
              if (this.currencyCode !== item.currencyCode) {
                this.currencyCode = item.currencyCode
              }
            }
          })
          this.paymentSources.forEach((method) => {
            if (method.paymentSourceType === PaymentSourceType.ACCOUNT_FUNDS && totalPending) {
              availableBalance += Number(method?.accountSourceDTO?.availableBalance)
              // Added to show toggle button text.
              this.availableMessage.show = true
            }
          })
        }
      } catch (error) {
        console.log('Unable to get funding transactions information', error)
      }
      this.totalPending = this.$moneyIntl(totalPending, this.currencyCode)
      this.availableBalance = this.$moneyIntl(availableBalance, this.currencyCode)
      this.totalBalance = this.$moneyIntl((totalPending + availableBalance), this.currencyCode)
    }
  }
}
</script>
