import { hasAuthority, hasAnyAuthority } from '@/components/shared/security/authorized-plugin'
import AuthenticationProvider from '@/components/shared/security/authentication-provider'
import {
  ACCOUNT_ACCESS,
  API_KEYS_MANAGE,
  FUNDING_MANAGE,
  QUALTRICS_API_KEYS_ACCESS,
  PLACE_ORDER_ACCESS,
  PLATFORM_ORDER_SETTINGS,
  RECIPIENT_WHITELIST_MANAGE,
  USER_ADMINISTRATION,
  AUTH_MANAGEMENT_MANAGE
} from '@/components/shared/constants/authority.constants'
import {
  API_KEY_MANAGEMENT,
  OAUTH_CLIENT_CRED_MGMT,
  ORDER_SETTINGS,
  QUALTRICS_SPLIT,
  RG_RECIPIENTS_WHITELIST_FEATURE,
  RG_SAML_SSO_UI
} from '@/components/shared/split/split-constants'
import { GET_TREATMENT_ENABLED } from '@/store/get-types'
import store from '@/store'

export const SettingsPages = Object.freeze({
  USERS: 'Users',
  ACCOUNTS: 'Accounts',
  RECIPIENTS: 'Recipients',
  ORDERS: 'Orders',
  SAML: 'SAML',
  TANGO_API: 'Tango API keys',
  QUALTRICS_API: 'Qualtrics API keys',
  OAUTH: 'OAuth client credentials',
  AUTHENTICATION: 'AUTHENTICATION'
})

export const SettingsPaths = Object.freeze({
  USERS: '/users',
  ACCOUNTS: '/groups',
  RECIPIENTS: '/recipients-whitelist',
  ORDERS: '/settings/order-settings-management',
  SAML: '/saml/manage',
  TANGO_API: '/settings/api-key-management',
  QUALTRICS_API: '/settings/qualtrics-api-key-management',
  OAUTH: '/settings/oauth-client-credentials-management',
  AUTHENTICATION: '/authentication'
})

export function getSettingsPages () {
  const pages = []

  if (hasAuthority(USER_ADMINISTRATION)) {
    pages.push(SettingsPages.USERS)
  }

  if (hasAnyAuthority([PLACE_ORDER_ACCESS, ACCOUNT_ACCESS, FUNDING_MANAGE])) {
    pages.push(SettingsPages.ACCOUNTS) // also called "groups" page
  }

  if (store.getters[GET_TREATMENT_ENABLED](RG_RECIPIENTS_WHITELIST_FEATURE) && hasAuthority(RECIPIENT_WHITELIST_MANAGE)) {
    pages.push(SettingsPages.RECIPIENTS)
  }

  if (store.getters[GET_TREATMENT_ENABLED](ORDER_SETTINGS) && hasAuthority(PLATFORM_ORDER_SETTINGS)) {
    pages.push(SettingsPages.ORDERS)
  }

  if (AuthenticationProvider.isEmulationSession() && store.getters[GET_TREATMENT_ENABLED](RG_SAML_SSO_UI)) {
    pages.push(SettingsPages.SAML)
  }

  if (store.getters[GET_TREATMENT_ENABLED](API_KEY_MANAGEMENT) && hasAuthority(API_KEYS_MANAGE)) {
    pages.push(SettingsPages.TANGO_API)
  }

  if (store.getters[GET_TREATMENT_ENABLED](QUALTRICS_SPLIT) && hasAuthority(QUALTRICS_API_KEYS_ACCESS)) {
    pages.push(SettingsPages.QUALTRICS_API)
  }

  if (store.getters[GET_TREATMENT_ENABLED](OAUTH_CLIENT_CRED_MGMT) && hasAuthority(API_KEYS_MANAGE)) {
    pages.push(SettingsPages.OAUTH)
  }

  if (hasAuthority(AUTH_MANAGEMENT_MANAGE)) {
    pages.push(SettingsPages.AUTHENTICATION)
  }

  return pages
}

export function getSettingsPaths () {
  const paths = []

  if (hasAuthority(USER_ADMINISTRATION)) {
    paths.push(SettingsPaths.USERS)
  }

  if (hasAnyAuthority([PLACE_ORDER_ACCESS, ACCOUNT_ACCESS, FUNDING_MANAGE])) {
    paths.push(SettingsPaths.ACCOUNTS) // also called "groups" page
  }

  if (store.getters[GET_TREATMENT_ENABLED](RG_RECIPIENTS_WHITELIST_FEATURE) && hasAuthority(RECIPIENT_WHITELIST_MANAGE)) {
    paths.push(SettingsPaths.RECIPIENTS)
  }

  if (store.getters[GET_TREATMENT_ENABLED](ORDER_SETTINGS) && hasAuthority(PLATFORM_ORDER_SETTINGS)) {
    paths.push(SettingsPaths.ORDERS)
  }

  if (AuthenticationProvider.isEmulationSession() && store.getters[GET_TREATMENT_ENABLED](RG_SAML_SSO_UI)) {
    paths.push(SettingsPaths.SAML)
  }

  if (store.getters[GET_TREATMENT_ENABLED](API_KEY_MANAGEMENT) && hasAuthority(API_KEYS_MANAGE)) {
    paths.push(SettingsPaths.TANGO_API)
  }

  if (store.getters[GET_TREATMENT_ENABLED](QUALTRICS_SPLIT) && hasAuthority(QUALTRICS_API_KEYS_ACCESS)) {
    paths.push(SettingsPaths.QUALTRICS_API)
  }

  if (store.getters[GET_TREATMENT_ENABLED](OAUTH_CLIENT_CRED_MGMT) && hasAuthority(API_KEYS_MANAGE)) {
    paths.push(SettingsPaths.OAUTH)
  }

  if (hasAuthority(AUTH_MANAGEMENT_MANAGE)) {
    paths.push(SettingsPaths.AUTHENTICATION)
  }

  return paths
}
