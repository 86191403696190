<template>
  <form>
    <v-radio-group
      id="radio-group"
      v-model="selectedMethod"
      class="mt-0"
      @change="$emit('selected-method', $event)"
    >
      <v-radio
        v-for="(fundingMethod, index) in fundingMethods"
        :key="index"
        class="t-radio-btn"
        :disabled="fundingMethod.disabled"
        name="payment"
        :value="fundingMethod"
      >
        <template #label>
          <funding-method-option
            :funding-method="fundingMethod"
            @show-dialog="$emit('show-dialog', $event)"
          />
        </template>
      </v-radio>
    </v-radio-group>
  </form>
</template>

<script>
import FundingMethodOption from '@/components/shared/lists/FundingMethodOption'

export default {
  name: 'FundingMethodRadioGroup',

  components: { FundingMethodOption },

  props: {
    selectedMethod: {
      type: Object,
      required: true
    },
    fundingMethods: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  // Pull radio button up to align with display name (only really affects radio buttons with helper text)
  ::v-deep .v-input--selection-controls__input {
    align-self: flex-start;
  }
</style>
