<template>
  <div
    v-if="showBanner"
    class="banner-space"
  >
    <div
      :style="{height: alertHeight + 'px !important' }"
      class="banner-warning"
    />
    <div
      ref="stickyAlert"
      class="sticky-alert"
      :class="{'alert-sidebar-open': sidebarOpen }"
      :style="{top: alertY}"
    >
      <!-- All might be there at the same time     -->
      <div
        v-if="isEmulation"
        id="emulation-banner"
        class="banner-content"
      >
        <v-icon class="info-icon">
          mdi-information-outline
        </v-icon> {{ $t(`${i18nPrefix}.emulation.banner`, { 'id': emulationID, 'name': platformName }) }}
      </div>
      <div
        v-if="isFrozen"
        id="frozen-banner"
        class="banner-content"
      >
        <v-icon class="info-icon">
          mdi-information-outline
        </v-icon> {{ $t(`${i18nPrefix}.frozenAccount`, { 'account': accountName}) }}
      </div>
      <div
        v-if="isUnverified"
        id="unverified-banner"
        class="banner-content"
      >
        <v-icon class="info-icon">
          mdi-information-outline
        </v-icon>
        <i18n
          path="portalfrontendApp.topBar.authEmail"
        >
          <span>{{ account.username }} <button
            class="link-button"
            @click="resendInvite"
          >{{ $t('portalfrontendApp.mfa.resendCodeEmail') }}</button></span>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_SELECTED_CUSTOMER_ACCOUNT,
  PRINCIPLE_GET_AUTHENTICATED,
  PRINCIPLE_GET_USER
} from '@/store/get-types'
import { EMULATION_AUTH_COMPLETE } from '../../shared/security/auth-events'
import * as actionTypes from '@/store/action-types'
import { mapActions } from 'vuex'
import { AccountStatus } from '@/components/platform/accounts-user-management/enum/accountStatus'
import { ERROR, SUCCESS } from '@/components/shared/alert/snack-constants'
import { IS_LOGGED_IN } from '@/components/shared/constants/authority.constants'

export default {
  name: 'EmulationBanner',
  props: {
    sidebarOpen: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      platformName: null,
      emulationAuthComplete: false,
      alertHeight: 0,
      alertY: 0,
      scrollY: 0
    }
  },
  computed: {
    showBanner () {
      return this.$hasAuthority(IS_LOGGED_IN) && (this.isEmulation || this.isFrozen || this.isUnverified)
    },
    account () {
      return this.$store.getters[PRINCIPLE_GET_USER]
    },
    selectedAccount () {
      return this.$store.getters[GET_SELECTED_CUSTOMER_ACCOUNT]
    },
    accountName () {
      return this.selectedAccount?.accountName
    },
    isEmulation () {
      return this.emulationAuthComplete && this.$isEmulationSession() && this.account
    },
    emulationID () {
      return this.$getEmulatedPlatformId()
    },
    isFrozen () {
      return this.$store.getters[PRINCIPLE_GET_AUTHENTICATED] && this.selectedAccount && this.selectedAccount.status === AccountStatus.FROZEN
    },
    isUnverified () {
      // Defaulted the value to false in order prevent issue with Auth0 SSO connection authentication.
      return false
    },
    i18nPrefix () {
      return 'portalfrontendApp.topBar'
    }
  },
  watch: {
    sidebarOpen: function () {
      this.handleResize()
      this.handleScroll()
    },
    selectedAccount: function () {
      this.handleResize()
      this.handleScroll()
    },
    // Because emulation id is stored for refresh when user first loads the saved id may be incorrect.
    // To show the correct value watch the id changing and set the emulation name correctly.
    emulationID: function () {
      this.init()
    }
  },
  async mounted () {
    this.$bus.$on(EMULATION_AUTH_COMPLETE, await this.init)
    if (this.$route.name !== 'EmulationTokenAuth') {
      if (this.$isEmulationSession()) {
        await this.init()
      }
    }
    await this.handleResize()
  },
  created () {
    window.addEventListener('scroll', this.handleScroll, true)
    window.addEventListener('resize', this.handleResize, true)
  },
  destroyed () {
    this.$bus.$off(EMULATION_AUTH_COMPLETE, this.init)
    window.removeEventListener('scroll', this.handleScroll, true)
    window.removeEventListener('resize', this.handleResize, true)
  },
  methods: {
    ...mapActions({
      getPlatformAsync: actionTypes.GET_PLATFORM_ASYNC,
      getSelectedCustomerAsync: actionTypes.GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC,
      updateSelectedAccount: actionTypes.UPDATE_THE_SELECTED_ACCOUNT,
      setSelectedCustomerAccount: actionTypes.UPDATE_THE_SELECTED_ACCOUNT
    }),
    async init () {
      this.emulationAuthComplete = true

      try {
        const emulatedPlatform = await this.getPlatformAsync({ platformClientId: this.$getEmulatedPlatformId() })
        this.platformName = emulatedPlatform.name
        await this.getSelectedCustomerAsync(true) // the "true" parameter allows for a force reload of the selected customer
      } catch (error) {
      }
    },
    async handleResize () {
      await this.$nextTick()
      this.alertHeight = this.$refs.stickyAlert?.clientHeight
    },
    handleScroll () {
      this.alertHeight = this.$refs.stickyAlert?.clientHeight
      const windowScroll = window.scrollY
      const downScroll = this.scrollY < windowScroll
      const tallerThanAlert = windowScroll > this.alertHeight
      if (this.isMobile && downScroll && tallerThanAlert) {
        this.alertY = (this.alertHeight * -1) + 'px'
      }
      if (!downScroll) {
        this.alertY = 0
      }
      this.scrollY = window.scrollY ? window.scrollY : this.scrollY
    },
    async resendInvite () {
      this.$http.post(`/api/users/${this.account.userId}/invite`)
        .then(() => {
          this.$snack(this.$t('userManagement.invite.success'), false, SUCCESS)
        })
        .catch((error) => {
          console.error('Error inviting user', error)
          this.$snack(this.$t('userManagement.invite.failure'), false, ERROR)
        })
        .finally(() => {
          this.hideUserActionDialog()
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/global.scss";
@import "~@/assets/scss/variables";

#unverified-banner{
  background-color: var(--t-color-primary-weakest);
  justify-content: start;
  font-weight: 400;
}

.banner-warning{
  position: relative;
  display: block;
  flex: 1;
}

.banner-content{
  display: flex;
  min-height: 40px;
  padding:1rem;
  margin: 5px 5px;
  font-size: 16px;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  background-color: var(--t-color-status-warning-weak);
  border-radius: 5px;
  color:var(--t-color-text);
}

.sticky-alert{
  background-color:#fff;
  position: fixed;
  top:0;
  width: calc(100% - 72px);
  left:62px;
  z-index: 9001;
  transition: left 300ms, width 300ms ease;
  margin:auto;

  &.alert-sidebar-open {
    width: calc(100% - 220px);
    left: 220px;
    @include media-breakpoint-up(md) {
      width: calc(100% - 233px);
    }
  }

  .info-icon {
    color:var(--t-color-text);
    margin-right:12px;
  }

  .link-button{
    font-weight: 500;
    color: var(--t-color-text-link);
    text-decoration: underline;
    margin-left:12px;
  }
}
</style>
