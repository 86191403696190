import store from '../store'
import { GET_TREATMENT_ENABLED } from '@/store/get-types'
import * as authorities from '@/components/shared/constants/authority.constants'
import * as rewardTemplatesConstants from '@/components/shared/constants/reward-templates.constants'
import {
  RG_ACH_DRAW,
  RG_SAML_SSO_UI,
  RG_USE_LINE_ITEM_TABLE_FOR_SPECIFIC_ORDER,
  RG_ACCOUNT_REDESIGN
} from '@/components/shared/split/split-constants'
import AuthenticationProvider from '@/components/shared/security/authentication-provider'
import {
  ACH_REQUEST_DETAILS_ROUTE,
  FUNDING_ROUTE,
  ORDER_HISTORY_LINE_ITEMS,
  ORDER_HISTORY_ROUTE,
  ORDER_HISTORY_SPECIFIC_ORDER,
  ORDER_HISTORY_SPECIFIC_ORDER_SPLIT_OFF
} from '@/router/route-name-constants'

import { ACH_REDIRECT_TO_ROUTE } from '@/components/platform/funding/FundingConstants'
import { isSplitEnabled } from '@/components/shared/split/split'
import {
  TC_ACCOUNTS_NEW_PAGE
} from '@/components/shared/constants/localstorage.constants'

const Group = () => import(/* webpackChunkName: "Group" */ '@/components/platform/accounts-user-management/groups/Group')
const CreateAccount = () => import(/* webpackChunkName: "CreateAccount" */ '@/components/platform/accounts-user-management/accounts/CreateAccount')
const RewardTemplates = () => import(/* webpackChunkName: "RewardTemplates" */ '@/components/platform/email-templates/RewardTemplates')
const EmailTemplateWrapper = () => import(/* webpackChunkName: "EmailTemplate" */ '@/components/platform/email-templates/EmailTemplateWrapper')
const EmailTemplateReview = () => import(/* webpackChunkName: "EmailTemplateReview" */ '@/components/platform/email-templates/EmailTemplateReview')
const PrintTemplate = () => import(/* webpackChunkName: "PrintTemplate" */ '@/components/platform/email-templates/print-templates/PrintTemplate')
const OrderHistory = () => import(/* webpackChunkName: "OrderHistory" */ '@/components/platform/orders/OrderHistory')
const OrderDetail = () => import(/* webpackChunkName: "OrderDetail" */ '@/components/platform/orders/order-details/OrderDetail')
const FundingMain = () => import(/* webpackChunkName: "FundingMain" */ '@/components/platform/funding/FundingMain')
const AchFlow = () => import(/* webpackChunkName: "AchFlow" */ '@/components/platform/funding/ach/AchFlow')
const AchSignerConfirmation = () => import(/* webpackChunkName: "AchSignerConfirmationModal" */ '@/components/platform/funding/ach/AchSignerConfirmationModal')
const FundingHistory = () => import(/* webpackChunkName: "FundingHistory" */ '@/components/platform/funding/history/FundingHistory')
const AccountsUserManagement = () => import(/* webpackChunkName: "AccountsUserManagement" */ '@/components/platform/accounts-user-management/AccountsUserManagement')
const ReportsMain = () => import(/* webpackChunkName: "ReportsMain" */ '../components/platform/reports/ReportsMain')
const RedirectToRoot = () => import(/* webpackChunkName: "RedirectToRoot" */ '../components/shared/RedirectToRoot')
const SamlProviderCreate = () => import(/* webpackChunkName: "SamlProviderCreate" */ '../components/platform/saml/SamlProviderCreate')
const BalanceAlertsManagement = () => import('@/components/platform/accounts-user-management/accounts-balance-alerts/BalanceAlertsManagement')
const AccountBalanceAlertFlow = () => import(/* webpackChunkName: "AccountBalanceAlertFlow" */ '@/components/platform/accounts-user-management/accounts-balance-alerts/AccountBalanceAlertFlow')
const RewardHistory = () => import('@/components/platform/orders/reward-history/RewardHistory')
const AuthenticationManagement = () => import(/* webpackChunkName: "SamlProviderCreate" */ '../components/platform/authentication/AuthenticationManagement')
const AccountManagement = () => import(/* webpackChunkName: "SamlProviderCreate" */ '@/components/platform/accounts/AccountManagement')

export default [
  {
    path: '/reports',
    name: 'Reports',
    component: async (goTo) => {
      const reportsMainModule = await ReportsMain()
      goTo(reportsMainModule)
    },
    meta: {
      segmentName: 'Reports',
      pageTitle: 'reports.title',
      activeSubComponent: 'reports',
      authorities: [authorities.IS_LOGGED_IN, authorities.ORDER_REPORT_MANAGE]
    }
  },
  {
    path: '/reports-generated',
    name: 'GeneratedReports',
    component: async (goTo) => {
      const reportsModule = await ReportsMain()
      goTo(reportsModule)
    },
    meta: {
      segmentName: 'Generated Reports',
      pageTitle: 'reports.title',
      activeSubComponent: 'generatedReports',
      authorities: [authorities.IS_LOGGED_IN],
      hasOneOfAuthorities: [authorities.ORDER_REPORT_MANAGE, authorities.ORDER_REPORT_ACCESS]
    }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: AccountsUserManagement,
    meta: {
      segmentName: 'List Groups',
      hasOneOfAuthorities: [authorities.ACCOUNT_ACCESS, authorities.FUNDING_MANAGE],
      authorities: [authorities.IS_LOGGED_IN],
      pageTitle: 'portalfrontendApp.coreCustomer.home.title',
      activeSubComponent: 'groups'
    },
    beforeEnter (to, from, next) {
      if (isSplitEnabled(RG_ACCOUNT_REDESIGN) && !sessionStorage.getItem(TC_ACCOUNTS_NEW_PAGE)) {
        next({ name: 'Accounts' })
      } else {
        next()
      }
    }
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: AccountManagement,
    meta: {
      segmentName: 'List Accounts',
      hasOneOfAuthorities: [authorities.ACCOUNT_ACCESS, authorities.FUNDING_MANAGE],
      authorities: [authorities.IS_LOGGED_IN],
      pageTitle: 'accountManagement.title',
      activeSubComponent: 'accounts'
    },
    beforeEnter (to, from, next) {
      if (isSplitEnabled(RG_ACCOUNT_REDESIGN) && !sessionStorage.getItem(TC_ACCOUNTS_NEW_PAGE)) {
        next()
      } else {
        next({ name: 'Groups' })
      }
    }
  },
  {
    path: '/group/:groupIdentifier',
    name: 'Group',
    component: Group,
    meta: {
      segmentName: 'View Group',
      hasOneOfAuthorities: [authorities.PLACE_ORDER_ACCESS, authorities.ACCOUNT_ACCESS, authorities.FUNDING_MANAGE],
      authorities: [authorities.IS_LOGGED_IN],
      pageTitle: 'portalfrontendApp.coreCustomer.home.title'
    }
  },
  {
    path: '/accounts/create',
    name: 'CreateAccount',
    component: CreateAccount,
    meta: {
      segmentName: 'New Account',
      authorities: [authorities.ACCOUNT_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.coreCustomer.home.title'
    }
  },
  {
    path: '/accounts/balance-alerts/create',
    name: 'AccountBalanceAlertCreate',
    component: AccountBalanceAlertFlow,
    meta: {
      segmentName: 'Account Balance Alert Create Flow',
      authorities: [authorities.ACCOUNT_MANAGE, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreAccountBalanceAlerts.create.title',
      mode: 'create'
    }
  },
  {
    path: '/accounts/balance-alerts/edit/:identifier',
    name: 'AccountBalanceAlertEdit',
    component: AccountBalanceAlertFlow,
    props: true,
    meta: {
      segmentName: 'Edit Account Balance Alert',
      authorities: [authorities.ACCOUNT_MANAGE, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreAccountBalanceAlerts.edit.title',
      mode: 'edit'
    }
  },
  {
    path: '/email-templates',
    name: 'EmailTemplates',
    component: RewardTemplates,
    meta: {
      segmentName: 'List Email Templates',
      authorities: [authorities.EMAIL_TEMPLATES_ACCESS, authorities.IS_LOGGED_IN],
      pageTitle: 'portalfrontendApp.rewardTemplates.home.title',
      activeSubComponent: rewardTemplatesConstants.EMAIL_TEMPLATES_SUB_COMPONENT
    },
    props: true
  },
  {
    path: '/print-templates',
    name: 'PrintTemplates',
    component: RewardTemplates,
    meta: {
      segmentName: 'List Print Templates',
      authorities: [authorities.EMAIL_TEMPLATES_ACCESS, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.rewardTemplates.home.title',
      activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATES_SUB_COMPONENT
    }
  },
  {
    path: '/email-templates/create/details',
    name: rewardTemplatesConstants.EMAIL_TEMPLATE_CREATE_DETAILS_ROUTE,
    component: EmailTemplateWrapper,
    meta: {
      segmentName: 'Create Email Template Details',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.emailTemplate.home.title',
      mode: 'create'
    }
  },
  {
    path: '/email-templates/create/access-level',
    name: rewardTemplatesConstants.EMAIL_TEMPLATE_CREATE_ACCESS_LEVEL_ROUTE,
    component: EmailTemplateWrapper,
    meta: {
      segmentName: 'Create Email Template Access Level',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.emailTemplate.home.title',
      mode: 'create'
    }
  },
  {
    path: '/email-templates/create/defaults',
    name: rewardTemplatesConstants.EMAIL_TEMPLATE_CREATE_DEFAULT_ROUTE,
    component: EmailTemplateWrapper,
    meta: {
      segmentName: 'Create Email Template Defaults',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.emailTemplate.home.title',
      mode: 'create'
    }
  },
  {
    path: '/email-templates/edit/:etid/details',
    name: rewardTemplatesConstants.EMAIL_TEMPLATE_EDIT_DETAILS_ROUTE,
    component: EmailTemplateWrapper,
    meta: {
      segmentName: 'Edit Email Template',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.emailTemplate.home.title',
      mode: 'edit'
    }
  },
  {
    path: '/email-templates/edit/:etid/access-level',
    name: rewardTemplatesConstants.EMAIL_TEMPLATE_EDIT_ACCESS_LEVEL_ROUTE,
    component: EmailTemplateWrapper,
    meta: {
      segmentName: 'Edit Email Template Access Level',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.emailTemplate.home.title',
      mode: 'edit'
    }
  },
  {
    path: '/email-templates/edit/:etid/defaults',
    name: rewardTemplatesConstants.EMAIL_TEMPLATE_EDIT_DEFAULT_ROUTE,
    component: EmailTemplateWrapper,
    meta: {
      segmentName: 'Edit Email Template Defaults',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.emailTemplate.home.title',
      mode: 'edit'
    }
  },
  {
    path: '/email-templates/duplicate/:etid/details',
    name: rewardTemplatesConstants.EMAIL_TEMPLATE_DUPLICATE_ROUTE,
    component: EmailTemplateWrapper,
    meta: {
      segmentName: 'Duplicate Email Template',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.emailTemplate.home.title',
      mode: 'duplicate'
    }
  },
  {
    path: '/email-templates/review',
    name: 'EmailTemplateReview',
    component: EmailTemplateReview,
    meta: {
      segmentName: 'Review Email Template',
      authorities: [authorities.EMAIL_TEMPLATES_ACCESS, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.emailTemplate.home.title'
    }
  },
  {
    path: '/print-templates/create',
    name: rewardTemplatesConstants.PRINT_TEMPLATE_CREATE_ROUTE,
    component: PrintTemplate,
    meta: {
      segmentName: 'Create Print Template',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.printTemplate.home.title',
      mode: 'create',
      activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATE_REDEMPTION_SUB_COMPONENT
    }
  },
  {
    path: '/print-templates/create/non-redemption',
    name: rewardTemplatesConstants.PRINT_TEMPLATE_NON_REDEMPTION_CREATE_ROUTE,
    component: PrintTemplate,
    meta: {
      segmentName: 'Create Print Template',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.printTemplate.home.title',
      mode: 'create',
      activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATE_NON_REDEMPTION_SUB_COMPONENT
    }
  },
  {
    path: '/print-templates/edit/:ptid',
    name: rewardTemplatesConstants.PRINT_TEMPLATE_EDIT_ROUTE,
    component: PrintTemplate,
    meta: {
      segmentName: 'Edit Print Template',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.printTemplate.home.title',
      mode: 'edit',
      activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATE_REDEMPTION_SUB_COMPONENT
    }
  },
  {
    path: '/print-templates/edit/:ptid/non-redemption',
    name: rewardTemplatesConstants.PRINT_TEMPLATE_NON_REDEMPTION_EDIT_ROUTE,
    component: PrintTemplate,
    meta: {
      segmentName: 'Edit Print Template',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.printTemplate.home.title',
      mode: 'edit',
      activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATE_NON_REDEMPTION_SUB_COMPONENT
    }
  },
  {
    path: '/print-templates/duplicate/:ptid',
    name: rewardTemplatesConstants.PRINT_TEMPLATE_DUPLICATE_ROUTE,
    component: PrintTemplate,
    meta: {
      segmentName: 'Duplicate Print Template Redemption Side',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.printTemplate.home.title',
      mode: 'duplicate',
      activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATE_REDEMPTION_SUB_COMPONENT
    }
  },
  {
    path: '/print-templates/duplicate/:ptid/non-redemption',
    name: rewardTemplatesConstants.PRINT_TEMPLATE_NON_REDEMPTION_DUPLICATE_ROUTE,
    component: PrintTemplate,
    meta: {
      segmentName: 'Duplicate Print Template Non Redemption Side',
      authorities: [authorities.EMAIL_TEMPLATES_MANAGE, authorities.IS_LOGGED_IN
      ],
      pageTitle: 'portalfrontendApp.printTemplate.home.title',
      mode: 'duplicate',
      activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATE_NON_REDEMPTION_SUB_COMPONENT
    }
  },
  {
    path: '/orders',
    name: ORDER_HISTORY_ROUTE,
    component: async (goTo) => {
      const orderHistory = await OrderHistory()
      goTo(orderHistory)
    },
    props: true,
    meta: {
      segmentName: 'Order History',
      authorities: [authorities.IS_LOGGED_IN],
      hasOneOfAuthorities: [authorities.ORDER_HISTORY_ACCESS, authorities.INDIVIDUAL_ORDER_HISTORY_ACCESS],
      pageTitle: 'portalfrontendApp.orderHistory.list.title',
      activeSubComponent: 'orders_order_history'
    }
  },
  {
    path: '/orders/line-items',
    name: ORDER_HISTORY_LINE_ITEMS,
    component: OrderHistory,
    meta: {
      segmentName: 'Order History Line Items',
      authorities: [authorities.IS_LOGGED_IN],
      hasOneOfAuthorities: [authorities.ORDER_HISTORY_ACCESS, authorities.INDIVIDUAL_ORDER_HISTORY_ACCESS],
      pageTitle: 'rewardHistory.pageHeader.tabs.lineItems',
      activeSubComponent: 'orders_line_item_history'
    }
  },
  {
    path: '/orders/line-item-history',
    name: 'RewardHistoryLineItems',
    component: RewardHistory,
    meta: {
      segmentName: 'Reward History Line Items',
      authorities: [authorities.IS_LOGGED_IN],
      hasOneOfAuthorities: [authorities.ORDER_HISTORY_ACCESS, authorities.INDIVIDUAL_ORDER_HISTORY_ACCESS],
      pageTitle: 'rewardHistory.pageHeader.tabs.lineItems',
      activeSubComponent: 'line_items'
    }
  },
  {
    path: '/orders/order-history',
    name: 'RewardHistoryOrders',
    component: RewardHistory,
    meta: {
      segmentName: 'Reward History Orders',
      authorities: [authorities.IS_LOGGED_IN],
      hasOneOfAuthorities: [authorities.ORDER_HISTORY_ACCESS, authorities.INDIVIDUAL_ORDER_HISTORY_ACCESS],
      pageTitle: 'rewardHistory.pageHeader.tabs.orders',
      activeSubComponent: 'orders'
    }
  },
  {
    path: '/orders/:referenceOrderId',
    name: ORDER_HISTORY_SPECIFIC_ORDER,
    component: OrderHistory,
    meta: {
      segmentName: 'Order Details',
      authorities: [authorities.IS_LOGGED_IN],
      hasOneOfAuthorities: [authorities.ORDER_HISTORY_ACCESS, authorities.INDIVIDUAL_ORDER_HISTORY_ACCESS],
      pageTitle: 'rewardHistory.pageHeader.tabs.orders',
      activeSubComponent: 'orders_line_item_history'
    },
    // TODO: Remove beforeEnter (and referenced component) with split cleanup
    beforeEnter (to, from, next) {
      if (isSplitEnabled(RG_USE_LINE_ITEM_TABLE_FOR_SPECIFIC_ORDER)) {
        next()
      } else {
        next({ name: ORDER_HISTORY_SPECIFIC_ORDER_SPLIT_OFF, params: to.params })
      }
    }
  },
  // TODO: remove this route with split cleanup
  {
    path: '/orders/:referenceOrderId/:lineItemNumber?',
    name: ORDER_HISTORY_SPECIFIC_ORDER_SPLIT_OFF,
    component: async (goTo) => {
      const orderDetail = await OrderDetail()
      goTo(orderDetail)
    },
    props: true,
    meta: {
      segmentName: 'Order Details',
      authorities: [authorities.IS_LOGGED_IN],
      hasOneOfAuthorities: [authorities.ORDER_HISTORY_ACCESS, authorities.INDIVIDUAL_ORDER_HISTORY_ACCESS],
      pageTitle: 'portalfrontendApp.orderHistory.detail.orderTitle'
    }
  },
  {
    path: '/order-complete/:referenceOrderId',
    redirect: '/orders/:referenceOrderId'
  },
  {
    path: '/funding',
    name: FUNDING_ROUTE,
    component: FundingMain,
    meta: {
      segmentName: 'Funding',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.home.fundingTabTitle',
      activeSubComponent: 'funding'
    }
  },
  {
    path: '/funding/history',
    name: 'NewFundingHistory',
    component: FundingMain,
    meta: {
      segmentName: 'Funding History',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_ACCESS],
      pageTitle: 'portalfrontendApp.coreFunding.history.title',
      activeSubComponent: 'funding_history'
    }
  },
  {
    path: '/funding-history',
    name: 'FundingHistory',
    component: FundingHistory,
    meta: {
      segmentName: 'Funding History',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_ACCESS],
      pageTitle: 'portalfrontendApp.coreFunding.history.title'
    }
  },
  {
    path: '/funding/ach/create/confirm-signer',
    name: 'AchConfirmSigner',
    component: async (goto) => {
      const confirmModal = await AchSignerConfirmation()
      goto(confirmModal)
    },
    props: true,
    meta: {
      segmentName: 'ach_confirmation',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.achPageTitles.create.step1',
      activeSubComponent: 'ach_confirmation'
    }
  },
  {
    path: '/funding/ach/create/details',
    name: 'AchCreateDetails',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](RG_ACH_DRAW)) {
        const achFlow = await AchFlow()
        goTo(achFlow)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    props: true,
    meta: {
      segmentName: 'Ach Create Flow Details',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.achPageTitles.create.step1',
      mode: 'create'
    }
  },
  {
    path: '/funding/ach/create/account-and-users',
    name: 'AchCreateAccountAndUsers',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](RG_ACH_DRAW)) {
        const achFlow = await AchFlow()
        goTo(achFlow)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      segmentName: 'Ach Create Flow Account and Users',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.achPageTitles.create.step2',
      mode: 'create'
    }
  },
  {
    path: '/funding/ach/create/review-and-authorize',
    name: 'AchCreateReviewAndAuthorize',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](RG_ACH_DRAW)) {
        const achFlow = await AchFlow()
        goTo(achFlow)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      segmentName: 'Ach Create Flow Review and Authorize',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.achPageTitles.create.step3',
      mode: 'create'
    }
  },
  {
    path: '/funding/ach/edit/:identifier/details',
    name: 'AchEditDetails',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](RG_ACH_DRAW)) {
        const achFlow = await AchFlow()
        goTo(achFlow)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    props: true,
    meta: {
      segmentName: 'Ach Edit Flow Details',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.achPageTitles.edit.step1',
      mode: 'edit'
    }
  },
  {
    path: '/funding/ach/edit/:identifier/account-and-users',
    name: 'AchEditAccountAndUsers',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](RG_ACH_DRAW)) {
        const achFlow = await AchFlow()
        goTo(achFlow)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    props: true,
    meta: {
      segmentName: 'Ach Edit Flow Account and Users',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.achPageTitles.edit.step2',
      mode: 'edit'
    }
  },
  {
    path: '/funding/ach/edit/:identifier/review-and-authorize',
    name: 'AchEditReviewAndAuthorize',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](RG_ACH_DRAW)) {
        const achFlow = await AchFlow()
        goTo(achFlow)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    props: true,
    meta: {
      segmentName: 'Ach Edit Flow Review and Authorize',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.achPageTitles.edit.step3',
      mode: 'edit'
    }
  },
  {
    path: '/funding/ach/request/:requestReferenceNumber',
    name: ACH_REQUEST_DETAILS_ROUTE,
    component: FundingMain,
    async beforeEnter (to, from, next) {
      const storedAchRoute = localStorage.getItem(ACH_REDIRECT_TO_ROUTE)
      if (storedAchRoute) {
        localStorage.removeItem(ACH_REDIRECT_TO_ROUTE)
        next({ name: storedAchRoute, params: to.params })
      } else {
        next()
      }
    },
    props: true,
    meta: {
      segmentName: 'Ach Request',
      authorities: [authorities.IS_LOGGED_IN, authorities.FUNDING_MANAGE],
      pageTitle: 'portalfrontendApp.coreFunding.ach.title',
      activeSubComponent: 'ach_tab'
    }
  },
  {
    // RL-3020 bypass rocks homepage and send to settings/user
    path: '/home',
    redirect: '/settings/user'
  },
  {
    path: '/saml/manage',
    name: 'Saml Provider Manage',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](RG_SAML_SSO_UI) &&
        AuthenticationProvider.isEmulationSession()) {
        const samlProviderCreateModule = await SamlProviderCreate()
        goTo(samlProviderCreateModule)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      hideSteps: true,
      segmentName: 'Saml Provider Manage',
      authorities: [authorities.IS_LOGGED_IN],
      pageTitle: 'saml.create.title'
    }
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: AuthenticationManagement,
    meta: {
      hideSteps: true,
      segmentName: 'Authentication Management',
      authorities: [authorities.IS_LOGGED_IN, authorities.ACCOUNT_MANAGE],
      pageTitle: 'settings.authentication.title'
    }
  },
  {
    path: '/accounts/balance-alerts',
    name: 'BalanceAlerts',
    component: async (goTo) => {
      const balanceAlertsModule = await BalanceAlertsManagement()
      goTo(balanceAlertsModule)
    },
    meta: {
      segmentName: 'Balance Alerts Manage',
      authorities: [authorities.IS_LOGGED_IN],
      pageTitle: 'portalfrontendApp.coreAccountBalanceAlerts.manage.pageTitle'
    },
    props: true
  }
]
