// SNACK TYPE
export const SNACKER_ID = 'snacker'
export const SUCCESS = 'success'
export const WARNING = 'warning'
export const ERROR = 'error'

// ICONS
export const SUCCESS_ICON = 'mdi-check-bold'
export const WARNING_ICON = 'mdi-information-variant'
export const ERROR_ICON = 'mdi-exclamation-thick'
export const CLOSE_ICON = 'mdi-close'

// TIMEOUT
export const DEFAULT_TIMEOUT = 7000
