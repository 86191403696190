import VeeValidate, { Validator } from 'vee-validate'
import { map, includes } from 'lodash'
import i18n from '@/i18n'
import { EmailTemplateMessageType } from '@/components/shared/constants/email-template.constants.js'
import { detectCardType } from '@/components/shared/funding/credit-card-service'
import { isValidEmailDomain } from '@/components/shared/forms/validations/validation-functions'

export const EMAIL_REGEX = '^(?:[\\w!#$%&\'*+=?`{|}~-]+(?:\\.[\\w!#$%&\'*+=?`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,63})$'
// Added to match backend not allowing any special characters, response recieved from https://rewardsgenius-api.public.dev.tangocard.com:9999/api/authenticate
export const EMAIL_SPECIAL_CHARACTER_REGEX = '^[_\'.@A-Za-z0-9-]*$'

// Tango email regex is the regex being used in BOS for validating recipient emails
export const TANGO_EMAIL_REGEX = '^$|^[\\\\w!#$%&\'*+=?`{|}~-]+(?:\\\\.[\\\\w!#$%&\'*+=?`{|}~-]+)*@(?:[a-zA-Z0-9-]+\\\\.)+[a-zA-Z]{1,63}$'
export const COMMA_SEPARATED_EMAIL_REGEX = '^([A-Za-z0-9~#\\-`!$%^&*_=+}\\|\\/{\'?]+(\\.[A-Za-z0-9~#\\-`!$%^&*_=+}\\|\\/{\'?]+)*@[A-Za-z0-9.-]+\\.[a-zA-Z]{2,255},?\\s*)+$'
export const DISPLAY_NAME_REGEX = '^[-a-zA-Z0-9 _]{5,100}$'
export const PHONE_NUMBER_REGEX = '^\\(?\\d{3}\\)?[- ]?\\d{3}[- ]?\\d{4}$'
// International phone numbers are complex - go for a more flexible approach for now and update if problems occur
export const PHONE_NUMBER_WITH_COUNTRY_CODE_REGEX = /^\+?[0-9 .()-]{1,15}$/g
export const PASSWORD_REGEX = '(?=^.{10,128}$)(?=.*\\d)(?=.*[!@#$%^&*%+\\\\/\'?:,(){}[\\]~`\\-_.]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$'
export const HEX_COLOR_REGEX = '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'
export const TAX_ID = '[1-9]{2}\\d?-\\d{7}'
export const BUSINESS_NUMBER = '[0-9]{15}'
export const CORE_XSS_REGEX = '[<>’“/]{1,}'
export const REPORT_NAME_REGEX = '^[A-Za-z0-9!@#$%^&*()_+ -]*$'
export const CYBERSOURCE_TEXT_REGEX = /[A-z\d !&'()+,-./:?@]*/g
export const ZIP_CODE_REGEX = /^\d{5}(?:[ -]\d{4})?$/g
const MUSTACHE_TAGS_EXIST_REGEX = '({+[^{}]*}+)'
const MUSTACHE_TAGS_MATCHER_GLOBAL = /{{{[\w-]+}}}|{{[\w-]+}}/g
const LEADING_MUSTACHE_TAGS = /^{{{?/
const TRAILING_MUSTACHE_TAGS = /}}}?$/
const CONTAINS_CURLY_BRACE = /[{}]/

const ALLOWED_TAGS_SUBJECT = ['reward_name', 'from_name', 'reward_name']
const ALLOWED_TAGS_MESSAGE_BODY = ['from_name', 'recipient_name', 'message', 'reward_name']
const ALLOWED_TAGS_CLOSING = ['from_name']

export const validateMustacheTags = (value) => {
  const validMustacheTagMatcher = MUSTACHE_TAGS_MATCHER_GLOBAL // Matches double or triple tags
  const valueWithoutValidTags = value.replace(validMustacheTagMatcher, '') // removes valid mustache tags

  // If value has a `{` or `}`, after stripping valid tags, it must be invalid
  return !valueWithoutValidTags.match(CONTAINS_CURLY_BRACE)
}
export const validateDynamicTags = (value, [modelType]) => {
  if (!value.match(new RegExp(MUSTACHE_TAGS_EXIST_REGEX))) {
    // If there aren't any mustache tags, no need to run this rule
    return true
  } else {
    let validTagsForInput = []
    switch (modelType) {
      case EmailTemplateMessageType.MESSAGE:
        validTagsForInput = ALLOWED_TAGS_MESSAGE_BODY
        break
      case EmailTemplateMessageType.SUBJECT:
        validTagsForInput = ALLOWED_TAGS_SUBJECT
        break
      case EmailTemplateMessageType.CLOSING:
        validTagsForInput = ALLOWED_TAGS_CLOSING
        break
    }
    const tagsInString = value.match(MUSTACHE_TAGS_MATCHER_GLOBAL) // Grabs all valid tags

    const data = {}
    let lastValueChecked
    const containsIllegalTag = tagsInString.some(line => {
      lastValueChecked = line
      const tagValue = line.replace(LEADING_MUSTACHE_TAGS, '').replace(TRAILING_MUSTACHE_TAGS, '') // takes off the {{}}, `{{tag}}` becomes `tag`
      return !validTagsForInput.includes(tagValue)
    })

    const valid = !containsIllegalTag
    if (!valid) data.illegalTag = lastValueChecked
    return { valid, data }
  }
}

function ValidatorPlugin (Vue) {
  Vue.use(VeeValidate)

  Validator.extend('displayName', {
    getMessage: (field) => 'The ' + field + ' value is not a valid display name.',
    validate: (value) => Boolean(!!value && value.match(new RegExp(DISPLAY_NAME_REGEX)))
  })

  Validator.extend('unique', {
    getMessage: (field) => 'The ' + field + ' value is not unique.',
    validate: (value, list) => Boolean(!!list && !list.includes(value))
  })

  Validator.extend('exists', {
    getMessage: (field) => 'The ' + field + ' value is not present.',
    validate: (value, list) => Boolean(!!list && list.includes(value))
  })

  Validator.extend('creditCardType', {
    getMessage (field, args, data) {
      const currencyCode = args[0]
      return `${field} evaluates to a type that is not in the allowed list for ${currencyCode}`
    },
    validate: (value, args) => {
      const currencyCode = args[0]
      const allowedCardTypes = ['visa', 'mastercard', 'other']
      if (currencyCode === 'USD') {
        allowedCardTypes.push('discover')
        allowedCardTypes.push('amex')
      }
      const detectedCardType = detectCardType(value)
      return Boolean(allowedCardTypes.includes(detectedCardType))
    }
  })

  Validator.extend('commaSeparatedEmail', {
    getMessage: (field) => 'The ' + field + ' value is not a valid comma separated email list.',
    validate: (value) => Boolean(!!value && value.match(new RegExp(COMMA_SEPARATED_EMAIL_REGEX)))
  })

  Validator.extend('maxLengthCommaDelimited', {
    getMessage: (field, args, { length }) => 'You have exceeded the max number of ' + length + '.',
    validate: (value, length) => ({
      valid: Boolean(!!value && value.split(',').length <= length),
      data: {
        length
      }
    })
  })

  Validator.extend('hexColor', {
    getMessage: (field) => 'The ' + field + ' value is not a valid color hex code.',
    validate: (value) => Boolean(!!value && value.match(new RegExp(HEX_COLOR_REGEX)))
  })

  Validator.extend('emailRegex', {
    getMessage: () => i18n.t('entity.validation.emailpattern'),
    validate: (value) => Boolean(!!value && value.match(new RegExp(EMAIL_REGEX)))
  })

  Validator.extend('emailSpecialCharacterRegex', {
    getMessage: () => i18n.t('entity.validation.specialcharacterpattern'),
    validate: (value) => Boolean(!!value && value.match(new RegExp(EMAIL_SPECIAL_CHARACTER_REGEX)))
  })

  Validator.extend('emailDomainBlacklisted', {
    getMessage: () => i18n.t('portalfrontendApp.rewardValidation.errors.recipientEmail.blacklisted.detail'),
    validate: isValidEmailDomain
  })

  Validator.extend('password', {
    getMessage: (field) => i18n.t('password.messages.mustMatchPattern'),
    validate: (value) => Boolean(!!value && value.match(new RegExp(PASSWORD_REGEX)))
  })

  Validator.extend('xss', {
    getMessage: (field) => field + ' must not contain any of the following: \', ", <, >, or / ',
    validate: (value) => Boolean(!!value && !value.match(new RegExp(CORE_XSS_REGEX)))
  })

  Validator.extend('phone', {
    getMessage: (field) => 'The ' + field + ' value is not a valid phone number.',
    validate: (value) => Boolean(!!value && value.match(new RegExp(PHONE_NUMBER_REGEX)))
  })

  Validator.extend('phoneWithCountryCode', {
    getMessage: (field) => `The ${field} is not a valid phone number`,
    validate: (value) => Boolean(!!value && value.match(new RegExp(PHONE_NUMBER_WITH_COUNTRY_CODE_REGEX)))
  })

  Validator.extend('taxId', {
    getMessage: (field) => 'The ' + field + ' value is not a valid tax id.',
    validate: (value) => Boolean(!!value && value.match(new RegExp(TAX_ID)))
  })

  Validator.extend('businessNumber', {
    getMessage: (field) => 'The ' + field + ' value is not a valid business number.',
    validate: (value) => Boolean(!!value && value.match(new RegExp(BUSINESS_NUMBER)))
  })

  Validator.extend('matches', {
    getMessage: (field) => 'The ' + field + ' value does not match the original',
    validate: (value, args) => {
      return value === args[0]
    }
  })

  Validator.extend('numbersEqual', {
    getMessage: (field) => 'The ' + field + ' value is not valid',
    validate: (a, b) => {
      return (parseFloat(parseFloat(a).toFixed(2)) === parseFloat(parseFloat(b).toFixed(2)))
    }
  })

  Validator.extend('authorizedEmail', {
    getMessage: () => 'This email is not authorized to receive rewards from this platform',
    validate: (email, whitelist) => {
      const whitelistEmailsAsLowercase = map(whitelist, whitelistEmail => whitelistEmail.toLowerCase())
      return includes(whitelistEmailsAsLowercase, email.toLowerCase())
    }
  })

  Validator.extend('tangoEmailRegex', {
    getMessage: (field) => 'The ' + field + ' value is not a valid email.',
    validate: (value) => Boolean(!!value && !value.match(new RegExp(TANGO_EMAIL_REGEX)))
  })

  Validator.extend('validMustacheTags', {
    getMessage: (field) => 'The ' + field + ' does not have valid mustache tags.',
    validate: validateMustacheTags
  })

  Validator.extend('validDynamicTags', {
    getMessage: (field, params, data) => {
      return `${data.illegalTag} is invalid.`
    },
    validate: validateDynamicTags
  })

  Validator.extend('requiredField', {
    getMessage: (field, { errorMessage }) => {
      return errorMessage || 'This field is required'
    },
    validate: (value, { validationPattern, validationLength }) => {
      return {
        valid: validationPattern !== null
          ? validateWithValidationPattern(value, validationPattern, validationLength)
          : validateWithoutValidationPattern(value, validationPattern, validationLength),
        // This data property and the computesRequired option below ensure this validation
        // is triggered even if the field is empty
        data: {
          required: true
        }
      }
    }
  }, { computesRequired: true })

  Validator.extend('wyndhamTourId', {
    getMessage: () => 'Tour ID is a required 8-digit number',
    validate: (value) => {
      const validationPattern = /^\d{8}$/
      const requiredLength = 8
      const isValid = validateWithValidationPattern(value, validationPattern, requiredLength)
      return {
        valid: isValid,
        data: {
          required: isValid
        }
      }
    }
  }, { computesRequired: true })

  Validator.extend('cybersourceTextField', {
    getMessage: (field, args, { invalidCharacters }) => {
      return `${field} contains invalid characters: ${invalidCharacters.join(', ')}`
    },
    validate: (value) => {
      const invalidCharacters = [...new Set(value.replace(CYBERSOURCE_TEXT_REGEX, '').split(''))]
      return {
        valid: invalidCharacters.length === 0,
        data: {
          invalidCharacters
        }
      }
    }
  })

  Validator.extend('zipCode', {
    getMessage: (field) => field + ' is not a valid zip code',
    validate: (value) => Boolean(!!value && value.match(new RegExp(ZIP_CODE_REGEX)))
  })

  Validator.extend('requiredAddress', {
    getMessage: (field) => `${field} is required`,
    validate: (value) => {
      const { country, stateProvince, addressLine1, city, postalCode } = value
      return {
        valid: country && stateProvince && addressLine1 && city && postalCode
      }
    }
  })

  Validator.extend('authConnectionDisplayName', {
    getMessage: (field, args, { errorMessage }) => errorMessage,
    validate: (value, { existingDisplayNames }) => {
      let isValid = true
      let errorMessage = ''

      if (existingDisplayNames && existingDisplayNames.includes(value)) {
        isValid = false
        errorMessage = i18n.t('settings.authentication.connections.errors.duplicateDisplayName')
      } else if (!value) {
        isValid = false
        errorMessage = i18n.t('settings.authentication.connections.errors.displayNameRequired')
      }

      return {
        valid: isValid,
        data: {
          errorMessage,
          required: true
        }
      }
    }
  }, { computesRequired: true })

  const validateWithValidationPattern = (value, validationPattern, validationLength) => {
    return value !== null && value !== undefined && value !== '' && value.match(validationPattern) && value.length <= validationLength
  }

  const validateWithoutValidationPattern = (value, validationPattern, validationLength) => {
    return value !== null && value !== undefined && value !== '' && value.length <= validationLength
  }
}

export {
  ValidatorPlugin
}
