<template>
  <transition
    enter-active-class="animated fadeIn quick-animation"
    leave-active-class="animated fadeOut quick-animation"
  >
    <div
      id="account-switcher"
      class="overflow-scroll account-switcher-overflow-properties fs-unmask animated fadeIn elevation-2"
    >
      <search-bar
        :id="accountSearch.id"
        :disabled="accountsLoading"
        :no-margin="true"
        :placeholder="accountSearch.placeholder"
        @search-bar-criteria="searchAccounts($event.searchTerm)"
      />
      <loading
        v-if="accountsLoading"
        :visible="accountsLoading"
        :loading-text="$t('portalfrontendApp.coreAccount.accountSwitcher.loadingAccountsMessage')"
        class="mx-auto"
      />
      <div
        v-else
        class="account-switcher-cards"
      >
        <div
          v-for="(account, index) in accountsToDisplay"
          :id="`account-card-${index}`"
          :key="account.accountID"
          class="account-card d-flex justify-content-between align-items-start"
        >
          <div class="account-card-information">
            <p
              v-b-tooltip.hover.d800.bottom="account.accountName"
              class="account-card-account-name"
              v-text="account.accountName"
            />
            <p
              v-b-tooltip.hover.d800.bottom="account.netsuiteID"
              class="account-card-account-number"
              v-text="account.netsuiteID"
            />
            <p
              v-b-tooltip.hover.d800.bottom="account.customerName"
              class="account-card-group-name"
              v-text="account.customerName"
            />
            <p
              v-if="accountHasAvailableBalance(account)"
              class="account-card-available-balance font-weight-bold"
            >
              <span
                class="account-card-available-balance-disclaimer color-tertiary-text"
                v-text="displayedCurrencySymbol(account)"
              />
              <span
                class="text-info"
                v-text="$money(account.availableBalance, { code: currentlySelectedAccount.currencyCode, symbol: '' })"
              />
            </p>
          </div>
          <div v-if="accountIsEqualTo(account, currentlySelectedAccount)">
            <span class="account-switcher-selected-icon" />
          </div>
          <div v-else>
            <v-btn
              class="btn-secondary"
              @click="switchAccount(account)"
            >
              {{ $t('portalfrontendApp.coreAccount.accountSwitcher.accountSwitchButton') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE, GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC } from '@/store/action-types'
import { GET_SELECTED_CUSTOMER_ACCOUNT } from '@/store/get-types'
import SearchBar from '../../shared/search-bar/SearchBar'
import Loading from '../../shared/Loading'
import { cloneDeep, sortBy } from 'lodash'
import { AccountSwitcherMixin } from './AccountSwitcherMixin'
import { SWITCH_ACCOUNT } from './account-switcher-events'
import { ACCOUNT_SWITCHED } from '@/components/shared/segment/track-place-order'
import { CUSTOMERS_SIZE } from '@/components/shared/constants'

export default {
  name: 'AccountSwitcher',
  components: {
    SearchBar,
    Loading
  },
  mixins: [
    AccountSwitcherMixin
  ],
  props: {
    onlyShowCurrencyCodes: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      accounts: [],
      accountsToDisplay: [],
      accountSearch: {
        id: 'account-search',
        placeholder: this.$t('portalfrontendApp.coreAccount.accountSwitcher.searchAccountPlaceholder'),
        searchTerm: ''
      },
      switchedAccountPercentage: 0,
      accountsLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentlySelectedAccount: GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    currencyCodesToShow () {
      return new Set(this.onlyShowCurrencyCodes)
    }
  },
  async mounted () {
    this.accountsLoading = true
    await this.getSelectedCustomerAsync()
    const originalAccounts = await this.getAccounts()
    this.accounts = this.sortAccounts(this.currentlySelectedAccount, originalAccounts).filter(account => account.active)
    this.accountsToDisplay = cloneDeep(this.accounts)
    this.accountsLoading = false
  },
  methods: {
    ...mapActions({
      setAccountSwitcherIsVisible: APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE,
      getSelectedCustomerAsync: GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC
    }),
    searchAccounts (searchTerm) {
      if (searchTerm === '') {
        this.accountsToDisplay = cloneDeep(this.accounts)
      } else {
        const searchResults = this.$search(searchTerm, this.accounts, ['accountName', 'customerName', 'netsuiteID'])
        this.accountsToDisplay = this.sortAccounts(this.currentlySelectedAccount, searchResults)
      }
    },
    async getAccounts () {
      try {
        const accountsResponse = await this.$http.get('api/customers', { params: { size: CUSTOMERS_SIZE } })
        let accounts = accountsResponse.data.filter((customer) => customer.accountName)
        if (this.onlyShowCurrencyCodes.length) {
          accounts = accounts.filter(({ currencyCode }) => this.currencyCodesToShow.has(currencyCode))
        }
        return sortBy(accounts, 'accountName')
      } catch (error) {
        console.error(error)
        this.$toast(this.$httpError(error, this.$t('portalfrontendApp.coreAccount.accountSwitcher.failedToLoadGroups')), 'danger')
      }
    },
    accountIsEqualTo (account, otherAccount) {
      return account && otherAccount && account.accountID === otherAccount.accountID && account.accountIdentifier === otherAccount.accountIdentifier
    },
    sortAccounts (selectedAccount, accounts) {
      const indexOfFoundSelectedAccount = accounts.findIndex((account) => this.accountIsEqualTo(account, selectedAccount))
      const sortedAccounts = cloneDeep(accounts)
      if (indexOfFoundSelectedAccount !== -1) {
        // sort the accounts so that the currently selected account is on the top
        const removedSelectedAccount = sortedAccounts.splice(indexOfFoundSelectedAccount, 1).shift() // shift is needed since splice returns an array
        sortedAccounts.unshift(removedSelectedAccount)
      }
      return sortedAccounts
    },
    switchAccount (accountToSwitchTo) {
      this.$bus.$emit(SWITCH_ACCOUNT, { account: accountToSwitchTo })
      this.trackSwitchAccount(accountToSwitchTo)
    },
    trackSwitchAccount (accountToSwitchTo) {
      try {
        this.$segment.track(ACCOUNT_SWITCHED, {
          account_switch_from: this.currentlySelectedAccount.accountName,
          account_switch_to: accountToSwitchTo.accountName
        })
      } catch (error) {
        console.error(`Error arose in segment ${ACCOUNT_SWITCHED} event`, error)
      }
    },
    displayedCurrencySymbol (account) {
      if (account) {
        return this.$currencySymbol(account.currencyCode)
      }
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/variables";
  @import "~@/assets/scss/mixins";

  #account-search {
    ::v-deep .search {
      margin-bottom: 5px;
      border: none;
    }
  }

  .account-card {
    padding: .75rem;
    background-color: var(--t-color-surface);
    margin-bottom: 4px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.20);

    .account-card-information {
      width: 65%;

      > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: max-content;
        max-width: 100%;

        &:last-child {
          margin: 0;
        }
      }
    }

    .account-card-available-balance-disclaimer {
      font-size: 10px;
    }
  }

    @include progress-circle($primary, $gray-light, $primary);

    .info-text {
      color: var(--t-color-weak);
      font-size: 24px;
      padding-top: 32px;
    }

  .account-switcher-selected-icon {
    width: 30px;
    height: 30px;
    background: no-repeat center url("../../../assets/images/account-switcher-check-mark.svg");
    background-size: 100%;
    display: inline-block;
  }

  .account-switcher-overflow-properties {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 62vh;
  }

  #account-switcher {
    position: fixed;
    right: 0;
    top: 70px; // height of the top nav bar
    background-color: var(--t-color-surface);
    height: calc(100% - 70px);
    z-index: $zindex-account-switcher;
    border-radius: 8px;
  }
</style>
