<template>
  <tc-dialog
    cancel-button-text="Close"
    :is-loading="loading"
    max-width="530"
    show-header-image
    :title="$t('portalfrontendApp.coreAccount.home.title')"
    :value="showDialog"
    @cancel="closeDialog"
  >
    <div class="switcher-dialog-content">
      <loading
        class="pt-9 pb-4"
        :visible="loading"
      />
      <div v-if="!loading">
        <search-bar
          v-if="showSearchBar"
          id="account-search"
          class="pb-4"
          :disabled="loading"
          :no-margin="true"
          :placeholder="$t('portalfrontendApp.coreAccount.accountSwitcher.searchAccountPlaceholder')"
          @search-bar-criteria="searchAccounts"
        />
        <div>
          <account-selector-group
            v-for="group in accountsByGroupsToDisplay"
            :key="group.identifier"
            :group="group"
            :selected-account="selectedAccountToDisplay"
            :show-group-header="showGroupHeader"
            @switch-to-account="switchToAccount"
            @open-funding-dialog="openFundingDialog"
          />
        </div>
      </div>
    </div>
    <template #leftBtn>
      <a v-if="canManageAccounts">
        <v-btn
          class="manage-accounts-link"
          text
          data-testid="left-btn"
          @click="handleLeftButtonClick"
        >
          {{ $t('portalfrontendApp.coreFunding.dialog.openNewAccounts') }}
          <v-icon
            class="pl-2"
          >
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </a>
    </template>
  </tc-dialog>
</template>

<script>
import Loading from '@/components/shared/Loading'
import TcDialog from '@/components/shared/tc-dialog/TcDialog'
import { mapActions, mapGetters } from 'vuex'
import { FETCH_ACCOUNTS_BY_GROUPS, UPDATE_TOKEN_FOR_CUSTOMER_ACCOUNT } from '@/store/action-types'
import { GET_ACCOUNTS_BY_GROUPS, GET_SELECTED_CUSTOMER_ACCOUNT } from '@/store/get-types'
import SearchBar from '@/components/shared/search-bar/SearchBar'
import { getAccount } from '@/components/platform/funding/account-utilities'
import { cloneDeep } from 'lodash'
import AccountSelectorGroup from '@/components/sidebar/account-switcher/AccountSelectorGroup'
import { ACCOUNT_SWITCHED } from '@/components/shared/segment/track-side-navigation'
import { ACCOUNT_ACCESS, FUNDING_MANAGE } from '@/components/shared/constants/authority.constants'
import { hasAnyAuthority } from '@/components/shared/security/authorized-plugin'
import { AccountStatus } from '@/components/platform/accounts-user-management/enum/accountStatus'
import { SET_LAST_SELECTED_CUSTOMER_ACCOUNT } from '@/store/mutation-types'

export default {
  name: 'AccountSwitcherDialog',

  components: {
    AccountSelectorGroup,
    Loading,
    SearchBar,
    TcDialog
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      accountsByGroupsToDisplay: [],
      allAccountGroupsFilteredAndSorted: [],
      loading: false,
      usingSearchBar: false,
      selectedAccountToDisplay: {}
    }
  },

  computed: {
    ...mapGetters({
      getAccountsByGroups: GET_ACCOUNTS_BY_GROUPS,
      selectedAccount: GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    showSearchBar () {
      if (this.accountsByGroupsToDisplay.length > 1) {
        // There's 2 groups to show
        return true
      } else if (this.accountsByGroupsToDisplay.length > 0 && this.accountsByGroupsToDisplay[0].accounts.length > 1) {
        // There's at least 1 group with multiple elements
        return true
      } else {
        // None of the above are true, but user searched to get to that point.
        return this.usingSearchBar
      }
    },
    showGroupHeader () {
      return this.accountsByGroupsToDisplay.length > 1
    },
    canManageAccounts () {
      return hasAnyAuthority([ACCOUNT_ACCESS, FUNDING_MANAGE])
    }
  },

  async mounted () {
    this.loading = true
    // TODO remove this hard setting once we can depend on the store being correct RG-6659
    await this.fetchAccountsByGroups()
    this.selectedAccountToDisplay = this.selectedAccount
    this.allAccountGroupsFilteredAndSorted = this.getFilteredAndSortedAccountsByGroups()
    this.accountsByGroupsToDisplay = cloneDeep(this.allAccountGroupsFilteredAndSorted)
    this.loading = false
  },

  methods: {
    ...mapActions({
      updateSelectedCustomerAccount: UPDATE_TOKEN_FOR_CUSTOMER_ACCOUNT,
      fetchAccountsByGroups: FETCH_ACCOUNTS_BY_GROUPS
    }),
    closeDialog () {
      this.$emit('close-dialog')
    },
    searchAccounts (searchEvent) {
      const { searchTerm } = searchEvent
      if (searchTerm) {
        this.usingSearchBar = true
        const resultGroups = []
        for (let i = 0; i < this.allAccountGroupsFilteredAndSorted.length; i++) {
          // Search Group Accounts
          const searchResults = this.$search(searchTerm, this.allAccountGroupsFilteredAndSorted[i].accounts, ['displayName', 'customerName', 'netsuiteID', 'groupDisplayName'])
          resultGroups[i] = cloneDeep(this.allAccountGroupsFilteredAndSorted[i])
          resultGroups[i].accounts = searchResults
        }
        this.accountsByGroupsToDisplay = cloneDeep(resultGroups)
      } else {
        this.usingSearchBar = false
        this.accountsByGroupsToDisplay = this.getFilteredAndSortedAccountsByGroups()
      }
    },
    async switchToAccount (account) {
      const previousAccount = this.selectedAccountToDisplay
      this.selectedAccountToDisplay = account
      const accountIdentifier = account.identifier
      if (accountIdentifier) {
        try {
          const accountToSwitch = await getAccount(accountIdentifier)
          await this.updateSelectedCustomerAccount(accountToSwitch)
          this.$store.commit(SET_LAST_SELECTED_CUSTOMER_ACCOUNT, account)
          this.$segment.track(ACCOUNT_SWITCHED)
        } catch (error) {
          this.selectedAccountToDisplay = previousAccount
          console.log(error)
          this.$toast(this.$t('portalfrontendApp.coreAccount.accountSwitcher.errorMessage'), 'danger')
        }
      }
    },
    openFundingDialog (account) {
      this.$emit('open-funding-dialog', account)
    },
    handleLeftButtonClick () {
      this.$router.push({ path: '/groups' })
      this.closeDialog()
    },
    getFilteredAndSortedAccountsByGroups () {
      return this.getAccountsByGroups
        .map(this.mapGroup.bind(this))
        // If the group is disabled or has no active accounts, do not display the group
        .filter(group => group.accounts.length > 0 && group.status === 'ACTIVE')
        .sort((a, b) => {
          // If a group contains the currently selected group, it should be sorted to the top
          if (a.accounts.some(account => account?.accountID === this.selectedAccountToDisplay?.accountID)) return -1
          if (b.accounts.some(account => account?.accountID === this.selectedAccountToDisplay?.accountID)) return 1

          // Otherwise, sort groups alphabetically
          return a.displayName.localeCompare(b.displayName)
        })
    },
    mapGroup (group) {
      // Filter out inactive accounts, add some info about the groups to each account, and sort the accounts
      group.accounts = group.accounts
        .filter(account => (account.status === AccountStatus.ACTIVE || account.status === AccountStatus.FROZEN))
        .map(account => {
          // The add funds dialog needs to know this identifier to initiate transfers
          account.customerIdentifier = group.identifier
          // Used for searching for accounts by group name in the search bar
          account.groupDisplayName = group.displayName
          return account
        })
        .sort((a, b) => {
          // If an account is selected, it should be sorted to the top
          if (a?.accountID === this.selectedAccountToDisplay?.accountID) return -1
          if (b?.accountID === this.selectedAccountToDisplay?.accountID) return 1

          // Otherwise, sort accounts alphabetically
          return a.displayName.localeCompare(b.displayName)
        })

      return group
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .search {
    border-radius: var(--t-radius-0);
  }

  .manage-accounts-link {
    color: var(--t-color-primary) !important;
  }
</style>
