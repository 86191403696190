export const ADD_CREDIT_CARD_CLICKED = 'Add Credit Card Clicked'
export const ADD_FUNDS_CLICKED = 'Add Funds Clicked'
export const CREDIT_CARD_ADDED = 'Credit Card Added'
export const BANK_ACCOUNT_CREATE_SUCCESS = 'Add bank successful'
export const BANK_ACCOUNT_CREATE_FAILURE = 'Add bank failed'
export const EDIT_BANK_ACCOUNT_SUCCESS = 'Edit bank account was successful'
export const EDIT_BANK_ACCOUNT_FAILURE = 'Edit bank account failed'
export const CONFIRM_ADD_FUNDS_CLICKED = 'Confirmed Add Funds Clicked'
export const CANCEL_ADD_FUNDS_CLICKED = 'Cancel Add Funds Clicked'
export const ACCOUNT_FUNDED = 'Account Funded'
export const FUNDING_DIALOG_OPENED = 'Funding Dialog Opened'
export const FUNDING_DEPOSIT_SUCCESS = 'Funds Deposited Successfully'
export const FUNDING_TRANSFER_SUCCESS = 'Funds Transferred Successfully'
export const MANAGE_FUNDS_FROM_DIALOG_CLICKED = 'Manage Funds Clicked in Funding Dialog'
export const FUNDING_DIALOG_VALIDATION_ERROR = 'Form Validation Error in Funding Dialog'
export const CC_DEPOSIT_FUNDS_STATUS = 'Credit Card Deposit Funds Status'
