<template>
  <v-app>
    <div
      id="app"
      :class="mainClasses"
      @click="validateSession"
    >
      <topbar
        v-if="topbarAvailable"
        :viewport="viewport"
      />
      <sidebar
        v-if="sidebarVisible"
        :viewport="viewport"
        :sidebar-open="sidebarOpen"
        @open-sidebar="handleOpenSidebar"
      />
      <div
        class="container-fluid"
        :class="{ 'visible-nav': sidebarVisible, 'sidebar-open': sidebarOpen }"
      >
        <div
          id="main-row"
          class="row"
        >
          <app-loader
            class="fixed-loader"
            :visible="loading"
          />
          <jws-refresher />
          <toaster />
          <snacker />
          <undeliverable-email-popup
            v-if="showUndeliverableEmailPopup"
          />
          <div
            id="portal-content-wrap"
            class="content d-flex flex-column"
            :class="portalContentWrapClasses"
          >
            <emulation-banner
              :visible="sidebarVisible"
              :sidebar-open="sidebarOpen"
              :is-mobile="isMobile()"
            />
            <router-view :viewport="viewport" />
          </div>
        </div>
      </div>
      <account-switcher
        v-if="accountSwitcherIsVisible"
        class="account-switcher-container"
        :only-show-currency-codes="currencyCodesForAccountSwitcher"
      />
      <common-modal />
    </div>
  </v-app>
</template>

<script>
import Sidebar from '@/components/sidebar/Sidebar'
import Topbar from '@/components/shared/topbar/Topbar'
import Toaster from '@/components/shared/Toaster'
import Snacker from '@/components/shared/Snacker'
import CommonModal from '@/components/shared/CommonModal'
import JwsRefresher from '@/components/authentication/JwsRefresher'
import Loading from '@/components/shared/Loading'
import store from '@/store'
import { mapGetters } from 'vuex'
import * as getTypes from '@/store/get-types'
import AccountSwitcher from '@/components/platform/accounts/AccountSwitcher'
import debounce from 'lodash/debounce'
import { SIDEBAR_OPEN } from '@/components/shared/constants/localstorage.constants'
import EmulationBanner from '@/components/authentication/emulation/EmulationBanner'
import UndeliverableEmailPopup from '@/components/shared/email/UndeliverableEmailPopup.vue'
import { CHAMELEON_SPLIT, WYNDHAM_POPUP } from '@/components/shared/split/split-constants'
import {
  INDIVIDUAL_ORDER_HISTORY_ACCESS,
  INDIVIDUAL_ORDER_HISTORY_MANAGE,
  IS_LOGGED_IN,
  ORDER_HISTORY_ACCESS,
  ORDER_HISTORY_MANAGE
} from '@/components/shared/constants/authority.constants'
import { validateAuthSessionKey } from '@/components/shared/security/auth-session-validation'
import {
  GET_PLATFORM,
  PRINCIPLE_GET_USER
} from '@/store/get-types'

const US_CURRENCY_CODE = 'USD'

export default {
  name: 'App',
  store,
  components: {
    UndeliverableEmailPopup,
    AccountSwitcher,
    Sidebar,
    Toaster,
    Snacker,
    CommonModal,
    JwsRefresher,
    'app-loader': Loading,
    Topbar,
    EmulationBanner
  },
  data () {
    return {
      // we want the default to be open and remember the result in localstorage
      sidebarOpen: localStorage.getItem(SIDEBAR_OPEN) !== 'false',
      userAgent: navigator.userAgent,
      platform: window.navigator.platform,
      viewport: {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
        aspectRatio: this.aspectRatio(),
        orientation: this.orientation(),
        isAndroid: this.isAndroid(),
        operatingSystem: this.operatingSystem(),
        browser: this.browser(),
        isMobile: this.isMobile()
      }
    }
  },
  computed: {
    ...mapGetters({
      usingNewLayout: getTypes.APP_GET_USING_NEW_LAYOUT,
      loading: getTypes.APP_GET_LOADING,
      singleTreatment: getTypes.GET_SINGLE_TREATMENT,
      topbarVisible: getTypes.APP_GET_TOPBAR_VISIBLE,
      firstEnter: getTypes.APP_GET_TOPBAR_FIRST_ENTER,
      accountSwitcherIsVisible: getTypes.APP_GET_ACCOUNT_SWITCHER_IS_VISIBLE,
      onlyShowUsdAccounts: getTypes.GET_ONLY_SHOW_USD_ACCOUNTS,
      account: PRINCIPLE_GET_USER,
      platformInfo: GET_PLATFORM
    }),
    isChameleonSplitEnabled () {
      return this.$isSplitEnabled(CHAMELEON_SPLIT)
    },
    mainClasses () {
      return {
        'gray-background': this.usingNewLayout,
        'white-background': !this.usingNewLayout && !this.sidePageVisible && !this.topbarAvailable,
        'topbar-invisible': !this.accountSwitcherIsVisible && this.topbarAvailable && !this.topbarVisible,
        'topbar-transition': !this.firstEnter
      }
    },
    isChromeLess () {
      return this.$route.meta.isChromeLess
    },
    showUndeliverableEmailPopup () {
      return this.$hasAnyAuthority([ORDER_HISTORY_MANAGE, ORDER_HISTORY_ACCESS, INDIVIDUAL_ORDER_HISTORY_ACCESS, INDIVIDUAL_ORDER_HISTORY_MANAGE]) && this.$isSplitEnabled(WYNDHAM_POPUP)
    },
    sidebarVisible () {
      return this.checkVisible(this.$route, 'sidebarVisible', true)
    },
    sidePageVisible () {
      return this.checkVisible(this.$route, 'sidePageVisible', false)
    },
    topbarAvailable () {
      return this.sidebarVisible
    },
    portalContentWrapClasses () {
      return {
        'col-12 overflow-auto': this.sidebarVisible || this.sidePageVisible || this.isChromeLess,
        'col-6 offset-3': !this.sidebarVisible && !this.sidePageVisible && !this.isChromeLess,
        'col-small-gutters col-no-gutters content-pages': this.usingNewLayout
      }
    },
    currencyCodesForAccountSwitcher () {
      if (this.onlyShowUsdAccounts) {
        return [US_CURRENCY_CODE]
      } else {
        return []
      }
    }
  },
  watch: {
    account () {
      this.setUserChameleonIdentity()
    },
    platformInfo () {
      this.setUserChameleonIdentity()
    }
  },
  async mounted () {
    window.addEventListener('resize', debounce(this.handleResize, 500))
    window.addEventListener('focus', this.validateSession)
    this.$oneTrust.init()
    this.setUserChameleonIdentity()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('focus', this.checkSession)
  },
  methods: {
    checkVisible (routeToCheck, metaPropertyToUse, defaultValueIfMetaDoesNotExist) {
      // For some reason on load, Vue Router will load an initial route with an empty state.
      // This happens on every page, but it comes back to bite us here because we will always default
      // to "true", even on the pages we do _NOT_ want the Topbar to load initially. Since the empty route
      // has no metadata available. Thus: we need to check if the route is valid (AKA it has a name) and then
      // proceed with seeing if the topbar should be visible.
      let isVisible
      if (this.routeIsValid(routeToCheck)) {
        const metaProperty = routeToCheck.meta[metaPropertyToUse]
        const metaPropertyExists = typeof metaProperty !== 'undefined' && metaProperty != null
        isVisible = metaPropertyExists ? metaProperty : defaultValueIfMetaDoesNotExist
      } else {
        // If we're not on a valid route, we shouldn't even show the thing we are trying to determine is visible or not
        isVisible = false
      }
      return isVisible
    },
    setUserChameleonIdentity () {
      if (this.isChameleonSplitEnabled && this.$hasAuthority(IS_LOGGED_IN)) {
        this.$chmln.init()
        this.$chmln.identify()
      }
    },
    routeIsValid (route) {
      return route && route.name
    },
    orientation () {
      let orientation
      const aspectRatio = this.aspectRatio()
      if (aspectRatio > 3) orientation = 'super-landscape'
      else if (aspectRatio > 1 && aspectRatio <= 3) orientation = 'landscape'
      else if (aspectRatio === 1) orientation = 'square'
      else if (aspectRatio < 1 && aspectRatio > 0.5) orientation = 'portrait'
      else if (aspectRatio <= 0.5) orientation = 'super-portrait'
      return orientation
    },
    aspectRatio () {
      return document.body.clientWidth / document.body.clientHeight
    },
    handleResize () {
      this.viewport.width = document.body.clientWidth
      this.viewport.height = document.body.clientHeight
      this.viewport.aspectRatio = this.aspectRatio()
      this.viewport.orientation = this.orientation()
    },
    operatingSystem () {
      const macOS = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68k']
      const PC = ['Win32', 'Win64', 'Windows', 'WinCE']
      const iOS = ['iPhone', 'iPad', 'iPod', 'iPhone Simulator', 'iPad Simulator', 'iPod Simulator']
      const Android = ['Android']
      const Linux = ['Linux']
      const platform = navigator.platform
      let OS
      if (macOS.includes(platform)) OS = 'Mac'
      else if (PC.includes(platform)) OS = 'PC'
      else if (iOS.includes(platform)) OS = 'iOS'
      else if (Android.includes(platform)) OS = 'Android'
      else if (Linux.includes(platform)) OS = 'Linux'
      return OS
    },
    isMobile () {
      return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    },
    isAndroid () {
      return /Android/ig.test(navigator.userAgent)
    },
    browser () {
      const UA = navigator.userAgent
      const vendor = navigator.vendor
      const isChrome = /Chrome/ig.test(UA) && /Google Inc/ig.test(vendor)
      const isIE = /MSIE/ig.test(UA)
      const isFirefox = /Firefox/ig.test(UA)
      const isSafari = /Safari/ig.test(UA) && /Apple Computer/ig.test(vendor)
      const isOpera = /OP/ig.test(UA)
      if (isChrome) return 'Chrome'
      else if (isIE) return 'Internet Explorer'
      else if (isFirefox) return 'Firefox'
      else if (isSafari) return 'Safari'
      else if (isOpera) return 'Opera'
      else return 'Unsupported Browser'
    },
    handleOpenSidebar (sidebarOpen) {
      this.sidebarOpen = sidebarOpen
      localStorage.setItem(SIDEBAR_OPEN, sidebarOpen)
    },
    validateSession () {
      validateAuthSessionKey()
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/global.scss";
@import "~@/assets/scss/variables";
@import '~@tangocard/swag/src/scss/partials/common/mango_colors';
// want to use an id as it has a powerful override to make sure these styles get applied
#app {
  font-weight: var(--t-font-weight-4);
}
// This was the only way I was able to override the styles of Vuetify for the status filter component
#app .menu-dropdown {
  box-shadow:var(--t-shadow-3);
  border: 1px solid var(--t-color-border) !important;
  border-radius: var(--t-radius-1) !important;
  margin-block-start: .25rem;
}

#app .v-menu__content {
  box-shadow:var(--t-shadow-3);
  border: 1px solid var(--t-color-border) !important;
  border-radius: var(--t-radius-1) !important;
  margin-block-start: .25rem;
}

#app .v-autocomplete__content {
  box-shadow:var(--t-shadow-3);
  border: 1px solid var(--t-color-border) !important;
  border-radius: var(--t-radius-1) !important;
  margin-block-start: .25rem;
}

#app a {
    color: var(--t-color-text-link) !important;
}

#app .v-tab:hover {
  text-decoration: none !important;
}
#app #core-orders-col-orderTotal {
  text-align: right;
  padding-inline-end: .5rem;
}

#app {
  ::v-deep .btn-success,
  ::v-deep .btn-primary,
  .btn-info {
    color: var(--t-color-text-inverted);
}

  &.white-background {
    padding-top: 30px
  }

  &.content-pages {
    background: var(--t-color-background);
  }

  &.topbar-invisible {
    transition: padding 1s ease 0s;
    padding-top: 0;
  }

  #main-row {
    position: relative;
    margin: 0;
  }

  .visible-nav {
    transition: 300ms;
    padding-left: 62px;

    &.sidebar-open {
      @include media-breakpoint-up(md) {
        padding-left: $iconav-width-open;
      }
    }
  }

  .account-switcher-container {
    width: 22.5%;
  }

  #portal-content-wrap {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .vue-select {
    height: 43px;
    line-height: 43px;

    input {
      &[type=search] {
        display: none;
      }
    }

    li {
      line-height: 43px;
      letter-spacing: 1px;
    }

    .flag-icon {
      margin-right: 10px;
    }

    &.searchable {
      .dropdown-toggle {
        line-height: 43px;
        height: 43px;
      }
    }

    .dropdown-toggle {
      .selected-tag {
        margin: 0 0 0 5px;
      }
      .clear {
        bottom: 3px;
        right: 27px;
      }
      .open-indicator {
        bottom: 1px;
      }

      .open-indicator {
        bottom: 8px;
      }

      .clear {
        bottom: 10px;
      }

      .selected-tag {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 8px 40px 8px 15px;
        letter-spacing: 1px;
        pointer-events: none;
      }
    }
  }
  .responsive-container {
    max-width: $vuetify-lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    &.small-container {
      max-width: $vuetify-sm;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

#app{
  width: 100%;
  height: 100%;
  .sticky-header-content {
    padding: 1rem 2rem 0;
    height: 100%;
  }

  .has-page-header {
    .sticky-header-content {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
}

#app,
body.modal-open {
  .tc-button {
    border: 1px solid $primary;
    background-color: transparent;
    color: $primary;
    font-size: 14px;

    &.cancel-button {
      margin: 0 20px 0 0;
    }

    &.save-button {
      background-color: var(--t-color-primary);
      color: var(--t-color-text-inverted);
    }

    &.delete-button {
      background-color: var(--t-color-status-danger);
      color: var(--t-color-text-inverted) !important;
      border: 0;
      margin: 0;
      transition: all 150ms linear;

      &:hover {
        background-color: var(--t-color-status-danger-weak);
        transition: all 400ms linear;
      }
    }

    &[disabled] {
      background: var(--t-color-surface-inverted);
    }
  }
}

@media (max-width: 600px) {
  #app {
    .has-page-header {
      .sticky-header-content {
        display: block;
      }
    }
  }
}

.v-overlay.v-overlay--active  {
  z-index: $zindex-modal !important;
}
.v-dialog__content {
  z-index: $zindex-modal !important;
}

i.icon-physical-fulfillment {
  margin-right: 16px;
  width: 16px;
  height: 24px;
  background: no-repeat url("./assets/images/icon-physical-fulfillment-black.svg") center;
}
i.icon-digital-fulfillment {
  margin-right: 16px;
  width: 16px;
  height: 24px;
  background: no-repeat url("./assets/images/icon-digital-fulfillment.svg") center;
}

#app,
.container-fluid,
#main-row {
  height: 100%;
  padding: 0;
}

.v-dialog {
  .v-label {
    font-size: 14px;
  }
}

div.v-application {
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;

  #app {
    height: 100%;

    .page-sub-header {
      position: absolute;
      display: block;
      background: var(--t-color-surface);
      z-index: $zindex-page-header;
      width: 100%;
    }

    .page-tertiary-nav {
      background: var(--t-color-surface);
    }

    .v-data-table__empty-wrapper {
      width: calc(100vw - 80px);
      display: flex;
      height: 100%;

      td {
        padding: 14px;
      }
    }
  }
}
@media (max-width: $breakpoint-lg - 1) {
  .v-application {
    #app {
      .account-switcher-container {
        width: calc(100% - 72px);
      }
    }
  }
}
@media (max-width: $breakpoint-md - 1) {
 .v-application {
   #app {
     .page-sub-header {
       margin-left: 0 !important;
       padding-left: 0 !important;
       width: 100%;
     }
     .account-switcher-container {
       width: 100%;
     }
   }
 }
}
#container-nav-tooltip {
  @include nav-tooltip("groups", 110px);
  @include nav-tooltip("email", 155px);
  @include nav-tooltip("funding", 104px);
  @include nav-tooltip("funding-history", 155px);
  @include nav-tooltip("user-settings", 137px);
  @include nav-tooltip("user-mgmt", 171px);
  @include nav-tooltip("place-order", 127px);
  @include nav-tooltip("qualtrics", 110px);
  @include nav-tooltip("beamer", 159px);
  @include nav-tooltip("order-history", 140px);
}

@media (max-width: $breakpoint-lg - 1) {
  #portal-content-wrap {
    padding: 0;
  }
}

#reward-search-bar .v-input {
  border: 1px solid var(--t-color-border);
  border-radius: 4px;
  font-weight: var(--t-font-weight-5);
  font-family: $gotham-font-family;
}

#reward-search-bar .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

#reward-search div[role=combobox]{
  font-weight: var(--t-font-weight-5);
}
</style>
