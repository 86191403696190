<template>
  <validation-observer
    ref="observer"
    v-slot="observer"
    data-test="observer"
  >
    <tc-dialog
      keep-in-dom
      :value="showDialog"
      :title="$t('portalfrontendApp.coreFunding.dialog.title')"
      :cancel-button-text="cancelButtonText"
      :confirm-button-text="confirmButtonText"
      :is-confirm-button-disabled="!isDialogValid(observer)"
      :is-loading="processingFunding"
      data-test="tcDialog"
      @cancel="closeDialog"
      @confirm="handleSubmit"
    >
      <loading
        v-if="loading"
        class="pt-9 pb-4"
        :visible="loading"
      />
      <div v-if="!loading">
        <dl>
          <dt>
            {{ $t('portalfrontendApp.coreFunding.dialog.accountHeader') }}
          </dt>
          <dd>
            <div
              v-if="showAccountHeader"
              class="funding-dialog-title font-weight-medium pb-2"
            >
              {{ selectedCustomerAccount.accountName }} - {{ accountBalance }}
              <v-tooltip
                id="account-tooltip"
                right
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="funding-account-tooltip"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                {{ $t('portalfrontendApp.coreFunding.dialog.accountSwitcherTooltip') }}
              </v-tooltip>
            </div>
          </dd>
        </dl>
        <v-tabs
          v-model="tab"
          hide-slider
          height="36px"
        >
          <v-tab
            v-for="item in items"
            :key="item"
            :value="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          class="pt-5"
        >
          <v-tab-item
            class="text-center"
            :transition="false"
            :reverse-transition="false"
          >
            <!--   This will be Deposit Funds Component       -->
            <div
              v-if="!hasFundingMethods"
              class="empty-funding"
              data-test="empty-funding"
            >
              {{ $t('portalfrontendApp.coreFunding.dialog.noFunding') }}
            </div>
            <div v-else>
              <validation-observer
                ref="depositObserver"
              >
                <deposit-funds-tab
                  ref="depositFundsTab"
                  :ach-accounts="achAccounts"
                  :credit-cards="creditCards"
                  :selected-customer-account="selectedCustomerAccount"
                  :current-platform="currentPlatform"
                  @processing-funding="processingFunding = true"
                  @finished-funding="handleDepositFundsCompleted"
                  @close-dialog="closeDialog"
                  @open-dialog="openDialog"
                />
              </validation-observer>
            </div>
          </v-tab-item>

          <v-tab-item
            class="text-center"
            :transition="false"
            :reverse-transition="false"
          >
            <!--   This will be Transfer Funds tab       -->
            <div
              v-if="!accounts.length"
              class="empty-funding"
            >
              {{ $t('portalfrontendApp.coreFunding.dialog.noAccounts') }}
            </div>
            <div v-else>
              <validation-observer
                ref="transferObserver"
              >
                <transfer-funds-tab
                  ref="transferFundsTab"
                  :current-account="selectedCustomerAccount"
                  :available-accounts="accounts"
                  @processing-funding="(processing) => processingFunding = processing"
                  @close-dialog="closeDialog"
                />
              </validation-observer>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <template
        #leftBtn
      >
        <a
          v-if="shouldShowLeftButton"
          :href="leftButtonLink"
        >
          <v-btn
            class="left-button"
            text
            @click="handleLeftButtonClick"
          >
            {{ leftButtonText }}
            <v-icon
              class="pl-2"
            >
              mdi-open-in-new
            </v-icon>
          </v-btn>
        </a>
      </template>
    </tc-dialog>
  </validation-observer>
</template>

<script>
import TcDialog from '@/components/shared/tc-dialog/TcDialog.vue'
import { mapGetters } from 'vuex'
import TransferFundsTab from '@/components/platform/funding/add-funds-dialog/TransferFundsTab.vue'
import { GET_PLATFORM } from '@/store/get-types'
import { ACTIVE } from '@/components/shared/constants/account-statuses.constants'
import { ValidationObserver } from 'vee-validate'
import Loading from '@/components/shared/Loading.vue'
import DepositFundsTab from '@/components/platform/funding/add-funds-dialog/DepositFundsTab'
import { DEPOSIT_TAB_ID, TRANSFER_TAB_ID } from '@/components/platform/funding/add-funds-dialog/AddFundsDialogConstants'
import { FUNDING_DIALOG_OPENED, MANAGE_FUNDS_FROM_DIALOG_CLICKED } from '@/components/shared/segment/track-funding'
import { CUSTOMERS_SIZE } from '@/components/shared/constants'

export default {
  name: 'AddFundsDialog',
  components: {
    DepositFundsTab,
    Loading,
    TransferFundsTab,
    TcDialog,
    ValidationObserver
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    selectedCustomerAccount: {
      type: Object,
      default () {
        return {
          accountName: '',
          platformClientId: '',
          accountIdentifier: ''
        }
      }
    },
    accountBalance: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      creditCards: [],
      achAccounts: [],
      accounts: [],
      tab: 0,
      items: [
        this.$t('portalfrontendApp.coreFunding.dialog.depositFunds'),
        this.$t('portalfrontendApp.coreFunding.dialog.transferFunds')
      ],
      loading: true,
      predicate: 'createdDate',
      processingFunding: false,
      firstOpen: true
    }
  },
  computed: {
    hasFundingMethods () {
      return !!(this.creditCards.length || this.achAccounts.length)
    },
    cancelButtonText () {
      return this.loading ? '' : this.$t('portalfrontendApp.coreFunding.dialog.cancel')
    },
    confirmButtonText () {
      if (this.loading) {
        return ''
      } else if (this.tab === DEPOSIT_TAB_ID && this.hasFundingMethods) {
        return this.$t('portalfrontendApp.coreFunding.dialog.depositTabConfirm')
      } else if (this.tab === TRANSFER_TAB_ID && this.accounts.length) {
        return this.$t('portalfrontendApp.coreFunding.dialog.transferTabConfirm')
      }
      return ''
    },
    leftButtonText () {
      return this.tab === DEPOSIT_TAB_ID
        ? this.$t('portalfrontendApp.coreFunding.dialog.openNewFunding')
        : this.$t('portalfrontendApp.coreFunding.dialog.openNewAccounts')
    },
    leftButtonLink () {
      if (this.tab === DEPOSIT_TAB_ID) {
        return this.$router.resolve({ path: '/funding' }).href
      }
      return null
    },
    shouldShowLeftButton () {
      // Check permissions here, if needed
      return this.tab === DEPOSIT_TAB_ID && !this.hasFundingMethods && !this.loading
    },
    showAccountHeader () {
      return !!(this.selectedCustomerAccount?.accountName && this.accountBalance)
    },
    ...mapGetters({
      currentPlatform: GET_PLATFORM
    })
  },
  watch: {
    selectedCustomerAccount () {
      // The account refreshes when a funding event successfully processes to show the new account balance. In that
      // case we don't want to reload the dialog data as the dialog is about to close.
      if (!this.processingFunding) {
        this.loadDialogData()
      }
    },
    showDialog (opened) {
      if (opened) {
        this.$segment.track(FUNDING_DIALOG_OPENED)
        this.loadDialogData()
      }
    }
  },
  methods: {
    handleLeftButtonClick () {
      this.$segment.track(MANAGE_FUNDS_FROM_DIALOG_CLICKED)
      this.$emit('left-btn-action-event')
    },
    isDialogValid (observer) {
      if (observer.pristine || observer.pending) return false

      if (this.tab === DEPOSIT_TAB_ID) {
        if (this.$refs.depositObserver) {
          const { ctx } = this.$refs.depositObserver
          return ctx.valid
            ? ctx.valid
            : false
        }
      } else if (this.tab === TRANSFER_TAB_ID) {
        if (this.$refs.transferObserver) {
          const { ctx } = this.$refs.transferObserver
          return ctx.valid
            ? ctx.valid
            : false
        }
      }
      return false
    },
    handleSubmit () {
      if (this.tab === DEPOSIT_TAB_ID) {
        this.$refs.depositFundsTab?.submitForm()
      } else if (this.tab === TRANSFER_TAB_ID) {
        this.$refs.transferFundsTab?.submitForm()
      }
    },
    closeDialog () {
      this.$emit('close-dialog')
    },
    openDialog () {
      this.$emit('open-dialog')
    },
    async loadDialogData () {
      this.loading = true
      await Promise.all([
        this.loadCreditCards(),
        this.loadAchAccounts(),
        this.loadCustomerAccounts()
      ])
      this.loading = false
    },
    async loadCreditCards () {
      const options = {
        params: {
          sort: this.sort()
        }
      }
      try {
        const { data } = await this.$http
          .get(`api/creditCards/platforms/accounts/${this.selectedCustomerAccount.accountIdentifier}`, options)
        this.creditCards = data
      } catch (error) {
        console.log('Loading credit cards error: ' + error)
      }
    },
    async loadAchAccounts () {
      try {
        const { data } = await this.$http.get(`api/ach/subscriptions/${this.selectedCustomerAccount.accountID}`)
        this.achAccounts = data
      } catch (error) {
        console.log('Loading ACH error: ' + error)
      }
    },
    async loadCustomerAccounts () {
      try {
        const { data } = await this.$http.get('api/customers', { params: { size: CUSTOMERS_SIZE, sort: 'displayName,asc' } })
        this.accounts = data.filter(account => account.status === ACTIVE).sort((a, b) => a.accountName.localeCompare(b.accountName))
      } catch (error) {
        console.log('loading accounts errors: ' + error)
        this.$toast(this.$httpError(error, this.$t(`${this.i18nPrefix}.getAccountsError`)), 'danger')
      }
    },
    sort () {
      return this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
    },
    handleDepositFundsCompleted () {
      this.processingFunding = false
    }
  }
}
</script>

<style scoped lang="scss">

.funding-dialog-title {
  font-size: var(--t-font-size-1);
  font-weight: var(--t-font-weight-5);
  color: var(--t-color-text);
}

.empty-funding {
  padding: 1rem 2rem;
}

.v-tabs-bar {
  .v-tab:not(.v-tab--active),
  .v-tab--disabled {
    color: var(--t-color-text) !important; // Not loving using the !important, but it's scoped, so...
  }
}

// Vuetify default styling does a carousel with the tabs on smaller displays, we never want that
::v-deep {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }
  .v-slide-group__wrapper .v-tabs-bar__content {
    width: 100% !important;
  }
}

.v-tab {
  width: 50%;
  border: var(--t-border-size-1) solid var(--t-color-border);
  border-right: unset;
  border-radius: var(--t-radius-1) 0 0 var(--t-radius-1);
  text-transform: initial;

  &:last-child {
    border: var(--t-border-size-1) solid var(--t-color-border);
    border-left: unset;
    border-radius: 0 var(--t-radius-1) var(--t-radius-1) 0;
  }

  &.v-tab--active {
    color: var(--t-color-primary);
    background: var(--t-color-primary-weakest);
    border: var(--t-border-size-1) solid var(--t-color-primary-weak);
  }
}

.funding-account-tooltip {
  vertical-align: bottom;
}

.left-button {
  color: var(--t-color-primary) !important;
}

</style>
